import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  height: 70px;
  border: 2px solid #0c5faf;
  border-radius: 11px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 15px;
  cursor:pointer;
  span {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 600;
    width: 330px;
  }
`
