import React from "react";
import {
  StyledBar,
  StyledBarContainer,
  StyledContainer,
  StyledDescription,
  StyledIndicator,
  StyledScale,
  StyledScaleItem,
  StyledScaleValue,
} from "./styles";
import {
  DEFAULT_GRADIENT,
  generateLinearGradient,
  getColorForPosition,
} from "./utils";
import { Props } from "./types";

function LevelIndicator({
  level,
  scale,
  descriptions,
  gradient = DEFAULT_GRADIENT,
}: Props) {
  const position = scale.findIndex((item) => item === level);

  return (
    <StyledContainer>
      <StyledBarContainer>
        <StyledBar
          background={generateLinearGradient(gradient.start, gradient.end)}
        />
      </StyledBarContainer>
      <StyledScale>
        {scale.map((value: string | number) => (
          <StyledScaleItem>
            {level === value && <StyledIndicator />}
            <StyledScaleValue>{value}</StyledScaleValue>
          </StyledScaleItem>
        ))}
      </StyledScale>
      {descriptions?.length! > 0 && (
        <StyledDescription
          color={getColorForPosition(
            position,
            scale.length,
            gradient.start.rgb,
            gradient.end.rgb
          )}
        >
          {descriptions?.[position]}
        </StyledDescription>
      )}
    </StyledContainer>
  );
}

export default LevelIndicator;
