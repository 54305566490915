import styled from "styled-components";

export const ResumeContainer = styled.div`
    * {
        font-family: "DM Sans", sans-serif;
    }
`;

export const PageHead = styled.div`
    h1 {
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        color: #3c505a;
        border-bottom: 1px solid #c2c2c2;
        margin: 0 0 16px 0;
        padding-bottom: 16px;
    }
`;

export const CompanyInfosBox = styled.div`
    border: 1px solid #dddddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 24px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 16px;
    .title {
        color: #3c505a;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
    }
    .infos-row {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 16px;
        .infos-group {
            display: flex;
            flex: 1;
            flex-direction: column;

            .infos-title {
                color: #4a4a4a;
                margin-bottom: 8px;
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
            }
            .item {
                display: flex;
                gap: 8px;
                color: #2e2e2e;
                font-size: 14px;
                line-height: 14px;
                margin-bottom: 7px;
                flex-wrap: wrap;
                span {
                    font-weight: 700;
                }
            }
        }
    }
    .contacts-row {
        .title {
            color: #4a4a4a;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;
        }
        .contacts {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            .contact {
                .title {
                    color: #2e2e2e;
                    font-size: 14px;
                    line-height: 14px;
                    margin-bottom: 4px;
                }
                .item {
                    display: flex;
                    gap: 8px;
                    color: #2e2e2e;
                    font-size: 14px;
                    line-height: 14px;
                    margin-bottom: 7px;
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
`;

export const PricesResume = styled.div`
    border: 1px solid #dddddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 24px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 16px;
    .resume-title {
        color: #3c505a;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 16px;
    }
    .resume {
        display: flex;
        align-items: center;
        gap: 16px;
        .values {
            .products {
                font-size: 22px;
                color: #3c505a;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 8px;
            }
            .contract {
                display: flex;
                gap: 16px;
                .item {
                    color: #4a4a4a;
                    font-size: 18px;
                    font-weight: 700;
                    display: flex;
                    gap: 6px;
                    align-items: center;
                    &:not(:first-child) {
                        color: #4a4a4a;
                        font-size: 18px;
                        font-weight: 700;
                        border-left: 1px solid #c2c2c2;
                        padding-left: 16px;
                    }
                    span {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
`;

export const ProductsList = styled.div`
    border: 1px solid #dddddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 24px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 16px;
    .products-title {
        color: #3c505a;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
    }
    .offer-content {
        .configs {
            display: flex;
            gap: 16px;
            align-items: center;
            border-bottom: 1px solid #c2c2c2;
            padding: 0 0 16px 8px;
            .form {
                width: 100%;
                border-left: 1px solid #c2c2c2;
                padding-left: 16px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .form-row {
                    display: flex;
                    gap: 16px;
                    .form-group {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        label {
                            color: #4a4a4a;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .ant-select,
                        .ant-input-number {
                            width: 100%;
                        }
                        .ant-select-selector {
                            border: 1px solid #e0e0e0 !important;
                            border-radius: 23px;
                        }
                        .ant-input-number {
                            border-radius: 8px;
                        }
                        .ant-input-number-handler-wrap {
                            border-radius: 0 8px 8px 0;
                        }
                        .ant-input-number-input-wrap,
                        .ant-input-number-input {
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
        .offer-template {
            width: 100%;
            display: flex;
            gap: 16px;
            .item {
                position: relative;
                display: flex;
                align-items: center;
                gap: 10px;
                color: #4a4a4a;
                span {
                    font-weight: bold;
                }
            }
            .separator {
                color: #c2c2c2;
            }
        }
        .prices {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-end;
            gap: 16px;
            border-bottom: 1px solid #c2c2c2;
            padding: 16px 0;
            .price-item {
                .unity-price {
                    font-weight: 700;
                    color: #4a4a4a;
                    font-size: 18px;
                    label {
                        font-size: 14px;
                        font-weight: 400;
                    }
                    span {
                        font-size: 14px;
                    }
                }
                .total-price {
                    color: #0161af;
                    font-weight: 700;
                    font-size: 18px;
                    label {
                        font-size: 14px;
                        color: #4a4a4a;
                        font-weight: 400;
                    }
                    span {
                        font-size: 14px;
                    }
                }
                &.last {
                    border-left: 1px solid #c2c2c2;
                    padding-left: 16px;
                }
            }
        }
        .offer-infos {
            padding: 16px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .title {
                font-weight: 700;
                color: #4a4a4a;
            }
            p {
                margin-bottom: 0;
                text-transform: capitalize;
            }
            .offer-items{
              padding-bottom: 16px;
              border-bottom: 1px solid #CCCCCC;
            }
        }
        .contract-infos {
            display: flex;
            gap: 32px;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #CCCCCC;
            &:last-child{
              border:none;
              padding-bottom: 0;
            }
            .item {
                font-size: 14px;
                font-weight: 400;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .title {
                    font-weight: 700;
                    color: #4a4a4a;
                }
            }
        }
        .details{
          border: 1px solid #DDDDDD;
          padding: 24px;
          border-radius: 8px;
          margin-top: 16px;
          .detail-title{
            font-size: 18px;
            color: #3C505A;
            font-weight: 700;
          }
          .delivery{
            color:#2E2E2E;
            font-size:14px;
            display: flex;
            gap: 8px;
            span{
              font-weight:700;

            }
          }
        }
    }
    .ant-collapse > .ant-collapse-item {
        margin-bottom: 20px;
    }
    .ant-collapse > .ant-collapse-item:last-child {
        margin-bottom: 0;
    }
    .clear-button {
        border-left: 1px solid #c2c2c2;
        padding: 0;
        padding-left: 10px;
        img {
            margin-right: 8px;
        }
    }
    .ant-collapse-content-box {
        padding: 36px;
    }
`;

export const WrapperImage = styled.div`
    .image-car {
        max-width: 100px;
    }
`;
export const ImportantInfos = styled.div`
    border: 1px solid #dddddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 24px;
    width: 96%;
    margin: 0 auto;
`;

export const Observation = styled.div`
    padding: 24px;
    margin: 0 auto;
    .ant-checkbox-wrapper {
        align-items: center;
        gap: 12px;
        margin-bottom: 32px;
        .check-label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: #333333;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            gap: 4px;
            span {
                font-size: 14px;
                font-weight: 400;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #0e61b0;
            border-color: #0e61b0;
        }
    }
    .obs-field {
        color: #333333;
        font-size: 14px;
    }
`;
