import styled from 'styled-components'

export const ConfirmationContent = styled.div`
   * {
        font-family: "DM Sans", sans-serif;
    }
    background: #F5F5F5;
    padding: 16px 0;
    h1{
        color: #3C505A;
        font-size: 22px;
        line-height: 22px;
        font-weight: 700;
        border-bottom: 1px solid #E0E0E0;
        padding-bottom:16px;
        margin-bottom: 16px;
    }
    .status{
        padding: 0 64px;
        margin-bottom: 32px;
        color: #333333;
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
    }
    .text{
        display: flex;
    align-items: center;
    padding: 0 64px;
    border: none;
    gap: 32px;
    margin-bottom: 32px;
        .content{
            border:none;
        }
        p{
            font-size: 14px;
    color: #333333;
    margin: 0;
        }
    }
    .action{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        border-top: 1px solid #C2C2C2;
        padding-top: 32px;
        margin-bottom: 32px;
        .btn-continue{
            border: 2px solid #0161AF;
    border-radius: 40px;
    font-weight: 700;
    font-size: 14px;
    color: #0161AF;
    padding: 5px 20px;
    height: auto;
        }
    }
`
