import styled from 'styled-components'

export const BreadcrumbContent = styled.div`
        background:#fff;
        border-bottom: 1px solid #E0E0E0;
   * {
        font-family: "DM Sans", sans-serif;
    }
    .container-xxl{
        padding-right: var(--bs-gutter-x, 8px);
        padding-left: var(--bs-gutter-x, 8px);
    }
    .breadcrumb-menu{
        display: flex;
        gap: 10px;
        padding: 8px 10px;

        position: relative;
        background:#fff;
        .item{
            cursor:pointer;
            color: #0161af;
            font-size: 12px;
            line-height: 12px;
            font-weight:700;
            &.current{
                cursor:auto;
                font-weight: 400;
                color: #333333;
            }
        }
    }
`
