import styled from 'styled-components'
import ArrowBG from "../../../../../assets/icons/step-arrow.svg";

export const CheckoutContent = styled.div`
    background-color: #F5F5F5;
    padding: 16px 0;
    h1.checkout-title{
        font-size: 22px;
        line-height: 22px;
        color: #3C505A;
        margin-bottom: 0;
    }

    .checkout-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        button{
            color: #0161AF;
            font-size: 14px;
            font-weight: 700;
            border: none;
            background: none;
            display: flex;
            align-items: center;
            box-shadow: none;
            &:disabled{
            background:none;
            }
            .print{
            display: flex;
            align-items: center;
            gap: 8px;
            }
        }
    }
    .ant-tabs-nav-wrap{
        display: flex;
        width: 100%;
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #C2C2C2;
        margin-bottom:16px;
        .ant-tabs-nav-list{
            width: 100%;
        }
        .ant-tabs-tab{
            display:flex;
            flex:1;
            align-items: center;
            justify-content: center;
            margin: 0;
            background-image: url(${ArrowBG});
            background-repeat: no-repeat;
            background-position: center left;
            background-size: 12px 18px;
            font-size: 14px;
            font-weight: 400;
            color: #828282;
            &.ant-tabs-tab-active{
                .ant-tabs-tab-btn{
                    color:#0161AF;
                    text-shadow:none;
                }
            }
        }
        .ant-tabs-ink-bar-animated{
            background:#0161AF;
        }
        
    }
    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before{
        display:none;
    }
    .wrapper-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        text-align: center;
        border-top: 1px solid #C2C2C2;
        padding-top: 16px;
        margin-bottom: 16px;
        div {
            width: 190px;
        }

        @media (max-width: 768px) {
            justify-content: center;
        }

        button {
            min-width: 84px;
            width: auto;
            border-radius: 26px;
            padding: 10px 16px 10px 16px;
            font-size: 12px;
            font-weight: 700;
            height: auto;
            &.btn-continue {
                min-width: 100px;
                background-color: #0161af;
                color: #fff;
            }
        }

        .btn-back {
            background: transparent;
            border: 2px solid #226fbb;
            color: #226fbb;
        }
    }
    .tab-item{
        display: flex;
        align-items: center;
        gap:8px;
        .tab-circle{
            width:24px;
            height:24px;
            background-color:#E0E0E0;
            border-radius:50%;
            font-size:14px;
            color:#9E9E9E;
            display: flex;
            margin: 0;
            align-items: center;
            justify-content: center;
            img{
                margin:0;
            }
        }
        &.tab-item-previous{
            .tab-circle{
                background-color:#E0EFFE;
            }
        }
        &.tab-item-active{
            .tab-circle{
                background-color:#0161AF;
                color:#ffffff;
            }
        }
    }
`
