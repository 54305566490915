import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Input, Button, Form as AntForm } from "antd";
import * as S from "./styles";
import api from "services/api";
import { IMaskInput } from "react-imask";
import { isValidCNPJ } from "../../utils/formValidations";

type FormValues = Yup.InferType<typeof validationSchema>;

const validationSchema = Yup.object({
    cnpj: Yup.string()
        .required("CNPJ é obrigatório")
        .min(14, "CNPJ deve ter 14 dígitos")
        .test("is-valid-cnpj", "CNPJ inválido", (value) => isValidCNPJ(value || "")),
});

const CompanyFinder = forwardRef(({ onSubmitSuccess }: any, ref) => {
    const formikRef: any = React.useRef<FormikProps<FormValues>>(null);
    const [companyInfos, setCompanyInfos] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!) || null);

    useImperativeHandle(ref, () => ({
        submitForm: () => formikRef.current?.submitForm(),
    }));

    const handleFindCompany = async () => {
        const cnpj = formikRef.current?.values.cnpj.replace(/\D/g, "");

        try {
            const userResponse = await api.get(`/users/${cnpj}/cpfCnpj`);
            const userId = userResponse.data?.userId;

            let companyInfos: any = {
                cnpj: cnpj,
                userId: userResponse.data.userId,
            };

            if (userId) {
                try {
                    const renewResponse = await api.get(`users/CheckUserRenew/${userId}`);
                    if (renewResponse.data) {
                        console.log("Usuário elegível para renovação", renewResponse.data);
                    } else {
                        console.log("Usuário não elegível para renovação");
                    }
                    companyInfos = { ...companyInfos, canRenew: renewResponse.data };
                    localStorage.setItem("@FleetSolutions:companyInfos", JSON.stringify(companyInfos));
                    onSubmitSuccess();
                } catch (renewError) {
                    console.error("Erro ao verificar elegibilidade do usuário:", renewError);
                }
            } else {
                console.log("Usuário não encontrado");
            }
        } catch (userError) {
            api.post("/signup/signUp-company", { cpfCnpj: cnpj }).then((res) => {
                const { userId } = res.data;
                let companyInfos: any = {
                    cnpj: cnpj,
                    userId: userId,
                };
                localStorage.setItem("@FleetSolutions:companyInfos", JSON.stringify(companyInfos));
                onSubmitSuccess();
            });
        }
    };

    return (
        <Formik
            initialValues={{
                cnpj: companyInfos?.cnpj || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleFindCompany();
            }}
            innerRef={formikRef as any}
        >
            {({ values, handleChange, handleBlur, touched, errors, handleSubmit, setFieldValue }) => (
                <S.CompanyForm>
                    <AntForm layout="vertical" onFinish={handleSubmit}>
                        <div className="fields-group">
                            <div className="title">
                                Dados do Cliente
                                <span className="sub-title">Digite o CNPJ do cliente para continuar</span>
                            </div>
                            <div className="fields">
                                <div className="fields-row">
                                    <AntForm.Item
                                        label={
                                            <span>
                                                CNPJ<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.cnpj && touched.cnpj ? "error" : ""}
                                        help={touched.cnpj && errors.cnpj}
                                    >
                                        <IMaskInput
                                            mask="00.000.000/0000-00"
                                            unmask={true}
                                            name="cnpj"
                                            value={values.cnpj}
                                            onAccept={(value: any) => setFieldValue("cnpj", value.replace(/\D/g, ""))}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="Digite o CNPJ"
                                            className="ant-input"
                                        />
                                    </AntForm.Item>
                                </div>
                            </div>
                        </div>
                    </AntForm>
                </S.CompanyForm>
            )}
        </Formik>
    );
});

export default CompanyFinder;
