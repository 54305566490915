import styled from 'styled-components'
import ImgWithFallback from 'components/ImgWithFallback'
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  background-color:#fff;

  .wrapper-print {
    width: 75%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;

    .print {
      display: flex;
      justify-content: flex-end;
      margin-right: 25px;
      align-items: center;
      svg {
        margin-right: 6px;
      }
    }

    .btn-print {
      border: transparent;
      background: transparent;
      color: #236fbc;
      width: auto;
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      text-transform:none;
    }
  }

  &.printable{
    padding-left:15px;
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    background:#fff;
    display:none;
    *{
      pageBreakInside: avoid;
    }
    img{
      width: 450px;
      display:block;
      margin-bottom:25px;
    }
    .infos-group{
      display: block;
      margin-bottom:30px;
      .title{
        font-size: 22px;
        font-weight: bold;
        border-bottom:1px solid;
        margin-bottom:5px;
        padding-bottom: 5px;
        color:#000;
        text-align:left;
      }
      .items{
        .item{
          display: flex;
          gap: 9px;
          font-size: 18px;
          span{
            font-weight: bold;
          }
        }
      }
      &.resume{
        margin-bottom: 25px;
        padding-bottom:25px;
        border-bottom:1px solid;
        .items{
          .item{
            display:block;
            pageBreakInside: avoid;
            ul{
              list-style:none;
            }
          }
        }
      }
    }
    @media print {
      display:flex;
    }
  }

  .title-benefits {
    margin-bottom: 20px;

    h1,
    p {
      color: #3d4f59;
      text-align: center;
    }
  }
`

export const Content = styled.div`

  display: flex;
  flex-direction: row;

  max-width: 1400px;
  margin: 0 auto;

  p {
    color: #0a0a0a;
  }

  @media (max-width: 1151px) {
    flex-direction: column;
    align-items: center;

    .image-car {
      width: 500px;
    }
  }

  @media (max-width: 520px) {
    .image-car {
      width: 300px;
    }
  }

  &.custom-optionals{
    max-width: 1400px;
  }


`

export const DetailsCar = styled.div`


  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 40%;
  max-width: 425px;
  min-width: 300px;
  margin-top: 25px;
&.custom-optionals-details{
  max-width: 800px;
  width: 50%;
}
  .reservation-params{
      position: relative;
          width: 100%;
      .loader{
        position: absolute;
        left: 0;
        top: 0;
        background: #ffffffbf;
        height: 100%;
        z-index: 9999;
        width: 80%;

        > div:first-child{
        height: 100%;
        
        }
      }
  }

  a {
    color: #989898;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
  }

  .options-offer {
    margin-top: 8px;
    width: 300px;

    p {
      margin: 8px 0px 0px 0px;
      font-size: 14px;
    }
  }

  .custom-optionals{
    width:100%;
    max-height:650px;
    overflow-x:scroll;
    .head-group{
      display: flex;
      gap: 6px;
    }
    span{
      font-size:12px;
    }
      h4{
      font-size:14px
      }
    .optional-group{
    border: 1px solid #999999;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 0px 6px #e9e9e9;
      &.selected{
        border-color:#236fbc;
        h4{
          color:#236fbc;
        }
      }
    }
  }

  .accessories-offer-audi {
    margin-top: 8px;
    width: 300px;

    p {
      margin: 8px 0px 0px 0px;
      font-size: 14px;
    }

    .item-accessories {
      max-width: 270px;
      width: 100%;
      padding: 0;
    }
  }

  h1 {
    color: #070707;
    font-size: 22px;
  }

  .plan {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 270px;

    .title-option {
      margin: 8px 0px 0px 0px;
      font-size: 14px;
    }
  }

  .info-important {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    span {
      color: #0a0a0a;
      font-size: 12px;
      margin: 4px;
      font-weight: bold;
    }

    p {
      margin: 4px;
    }

    margin-top: 30px;

    .surplus-km {
      margin-right: 40px;
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }

  .colors {
    margin-top: 15px;

    p {
      padding: 0;
      color: #070707;
      font-weight: 500;
      margin: 0;
    }

    img {
      margin-right: 5px;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      padding: 2px;
      border: 1px solid black;
    }

    .color-name {
      width: 100px;
      padding: 0;
      font-size: 12px;
      word-break: break-word;
    }

    .color-options {
      display: flex;

      .color-type {
        display: flex;
        flex-direction: column;
        z-index: 0;
        cursor: pointer;

        span {
          margin-bottom: 30px;
        }
      }
    }

    span {
      pointer-events: none;
      cursor: not-allowed;
      text-decoration: none;
      font-style: italic;
      height: 0px;
    }

    .ant-radio-button-wrapper {
      .ant-radio-button-wrapper,
      .ant-radio-group-solid .ant-radio-button-wrapper-checked {
        border: 1px #fff;
        color: #000;
        background-color: #fff;
        border-color: #fff;
        border-left: 1px solid #fff;
        box-shadow: 0 0 0 0;

        :hover {
          color: #1890ff;
          background-color: #fff;
          border-color: #fff;
        }
      }
    }
  }

  .plan {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 270px;
  }

  .info-important {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    span {
      color: #0a0a0a;
      font-size: 12px;
      margin: 4px;
      font-weight: bold;
    }

    p {
      margin: 4px;
    }

    margin-top: 30px;

    .surplus-km {
      margin-right: 40px;
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }

  .value-plan {
    margin-top: 25px;
  }
`

export const ValuePlan = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-left: 4px;
  color: #236fbc;
`

export const ImageCar = styled(ImgWithFallback)`
  display: inline-block;
  position: relative;
  height: auto;
  margin-bottom: 25px;
  margin-top: 20px;
  width: 800px;


  @media (max-width: 1081px) {
    margin-bottom: -50px;
  }
`

export const TextCar = styled.p`
  font-size: 12px;
  color: #d5d5d5;
  color: black;
`
export const ButtonLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1160px;
  width: 90%;
  flex-direction: column;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  button {
    width: 300px;
    border-radius: 24px;
    border: 2px solid #226fbb;
    font-size: 14px;
    height: 47px;
    margin-bottom: 20px;
    text-transform: uppercase;

    &.btn-continue {
      background-color: #236fbc;
      color: white;
    }
    &.btn-back {
      background-color: white;
      color: #236fbc;
    }
    @media (min-width: 768px) {
      margin-bottom: 0;
      width: 186px;
    }
  }
`

export const Colors = styled.div`
  display: flex;

  & > .color-options {
    flex-wrap: nowrap;
    flex-direction: column;
    margin-right: 15px;

    & > div {
      display: flex;
      margin-top: 25px;

      & > .color-type {
        margin-right: 0;
      }
    }
  }
`

export const AlignLeft = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-left: 4px !important;
  }
`
export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 275px;
  justify-content: center;

  h3 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
  }

  p {
    margin-bottom: 40px;
  }

  input {
    border: 2px solid #cfd7d9;
    border-radius: 30px;
    width: 256px;
    height: 41px;
  }

  button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
`



export const ConfigOfferContent = styled.div`
    * {
        font-family: "DM Sans", sans-serif;
    }
  background-color:#F5F5F5;
  padding:16px 0;
  .custom-optionals {
    width: 100%;
    max-height: 350px;
    overflow-x: scroll;
    .head-group {
        display: flex;
        gap: 6px;
    }
    span {
        font-size: 12px;
    }
    h4 {
        font-size: 14px;
        color: #0e61b0;
        font-weight: 700;
    }
    .optional-group {
        border: 1px solid #999999;
        margin-bottom: 15px;
        padding: 10px;
        cursor: pointer;
        box-shadow: 0 0px 6px #e9e9e9;
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #0e61b0;
            border-color: #0e61b0;
        }
        &.selected {
            border-color: #0e61b0;
            h4 {
                color: #236fbc;
            }
        }
    }
}

  .offer-content{
    .configs{
      display: flex;
      gap: 16px;
      align-items: center;
      border-bottom: 1px solid #C2C2C2;
      padding: 0 0 16px 8px;
      .form{
        width: 100%;
        border-left: 1px solid #C2C2C2;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .form-row{
          display: flex;
          gap:16px;
          &.custom-form-row{
            flex-direction: column;
          }
          .form-group{
            display: flex;
            flex-direction: column;
            flex:1;
            label{
              color:#4A4A4A;
              font-size:14px;
              font-weight:400;
            }
            .ant-select,.ant-input-number{
              width: 100%;
            }
            .ant-select-selector{
                border: 1px solid #E0E0E0 !important;
                border-radius: 23px;
            }
            .ant-input-number{
              border-radius: 8px;
            }
            .ant-input-number-handler-wrap{
              border-radius: 0 8px 8px 0;
            }
            .ant-input-number-input-wrap,
            .ant-input-number-input{
              border-radius: 8px;
              
            }
          }
        }
      }
    }
    .prices{
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      gap:16px;
      border-bottom: 1px solid #C2C2C2;
      padding: 16px 0;
        .price-item{
          .unity-price{
            font-weight: 700;
            color: #4A4A4A;
            font-size: 18px;
            display: flex;
            align-items: baseline;
            gap: 4px;
            label{
              font-size:14px;
              font-weight:400;
            }
            span{
              font-size:14px;  
            }
          }
          .total-price{
            color:#0161AF;
            font-weight:700;
            font-size:18px;
            display: flex;
            align-items: baseline;
            gap: 4px;
            label{
              font-size:14px;
              color:#4A4A4A;
              font-weight:400;
            }
            span{
              font-size:14px;  
            }
          }
          .price-wrapper{
            position: relative;
            display: flex;
            align-items: baseline;
          }
          &.last{
            border-left:1px solid #C2C2C2;
            padding-left:16px;
          }
        }
    }
    .offer-infos{
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .title{
        font-weight:700;
        color:#4A4A4A;
      }
      p{
        margin-bottom:0;
        text-transform:capitalize;
      }
    }
    .contract-infos{
      display: flex;
      gap: 32px;
      .item{
          font-size:14px;
          font-weight:400;

        .title{
          font-weight:700;
          color:#4A4A4A;
        }
      }
    }

  }
      .clear-button{
      border-left: 1px solid #C2C2C2;
      padding: 0;
      padding-left: 10px;
      img{
        margin-right: 8px;
      }
    }
    .ant-collapse-content-box{
      padding:36px;
    }

    .buttons {
    width: 100%;
    margin: 10px auto;
    border-radius: 24px;
    flex-wrap: wrap;
    text-align: right;


    .wrapper-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        text-align: center;
        border-top: 1px solid #C2C2C2;
        padding-top: 16px;
        margin-bottom: 16px;
        div {
            width: 190px;
        }

        @media (max-width: 768px) {
            justify-content: center;
        }

        button {
            min-width: 84px;
            width: auto;
            border-radius: 26px;
            padding: 10px 16px 10px 16px;
            font-size: 12px;
            font-weight: 700;
            height: auto;
            &.btn-continue {
                min-width: 100px;
                background-color: #0161af;
                color: #fff;
            }
        }

        .btn-back {
            background: transparent;
            border: 2px solid #226fbb;
            color: #226fbb;
        }
    }
}

`

export const PageHead = styled.div`
.configs-head{
  border-bottom:1px solid #E0E0E0;
  margin: 16px 0;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button{
    color: #0161AF;
    font-size: 14px;
    font-weight: 700;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    box-shadow: none;

    &:disabled{
      background:none;
    }
    .print{
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
  h1{
    font-size:22px;
    font-weight:700;
    line-height:22px;
    color:#3C505A;
  }
`
export const WrapperImage = styled.div`
  .image-car {
    max-width: 100px;
  }
`