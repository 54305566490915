import styled from "styled-components";
import CheckDelivery from "../../../../../../assets/icons/check-delivery.svg";

export const Container = styled.div`
    * {
        font-family: "DM Sans", sans-serif;
    }
display: flex;
flex-direction: column;

.description {
    color: #3c505a;
    font-size: 20px;
    text-align: center;
    margin: 0;
}

.title {
    color: #17536c;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin: 28px 0 0 0;
}

.buttons {
    width: 100%;
    margin: 10px auto;
    border-radius: 24px;
    flex-wrap: wrap;
    text-align: right;

    .ejAGpi {
        width: auto;
    }

    .wrapper-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        text-align: center;

        div {
            width: 190px;
        }

        @media (max-width: 768px) {
            justify-content: center;
        }

        button {
            min-width: 65px;
            width: auto;
            border-radius: 26px;
            padding: 6px 16px 6px 16px;
            font-size: 14px;
            font-weight: 700;
            height: auto;
            &.btn-continue {
                background-color: #0161af;
                color: #fff;
            }
        }

        .btn-back {
            background: transparent;
            border: 2px solid #226fbb;
            color: #226fbb;
        }
    }
}

@media (max-width: 600px) {
    .buttons {
        justify-content: center;
    }
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 16px;
}
.ant-collapse {
    background: none;
    border: none;

    .ant-collapse-header {
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        padding: 16px;
        align-items: center !important;
        background: #d9d9d9;
        border-radius: 16px;
        .custom-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items:center;
            color:#2E2E2E;
            .action {
                button {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    gap: 5px;
                    cursor: pointer;
                    color: #4A4A4A;
                    border-left: 1px solid #C2C2C2;
                }
            }
        }
    }



    .ant-collapse-item {
        margin-bottom: 32px;
        border: 1px solid #cccccc;
        border-radius: 16px;
        &.ant-collapse-item-active {
            .ant-collapse-header {
                border-radius: 16px 16px 0 0;
                border-bottom: 1px solid #cccccc;
            }
        }
    }
    .ant-collapse-content {
        border: none;
        border-radius: 0 0 16px 16px;
    }
}

.car-infos {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
    .car-image {
        width: 10%;
        margin-right: 25px;
        img {
            max-width: 100%;
        }
    }
    .infos {
        display: flex;
        align-items: center;
        gap: 15px;
        flex: 1;
        flex-direction: column;
        .items {
            width: 100%;
            display: flex;
            gap: 16px;
            .item {
                position: relative;
                display: flex;
                align-items: center;
                gap: 10px;
                color: #4A4A4A;
                span {
                    font-weight: bold;
                }
                
            }
            .separator{
                color:#C2C2C2;
            }
        }
    }
}

.page-head {
    h1 {
        color: #3C505A;
        font-size:18px;
        font-weight: 700;
        line-height: 18px;
        line-height: 1;
    }
    span {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 1;
        position: relative;
        display: block;
        border: none;
        padding: 0;
        border-bottom: 1px solid #C2C2C2;
        padding-bottom: 16px;
    }
}

.all-selection {
    button {
        min-width: 65px;
        width: auto;
        border-radius: 26px;
        padding: 5px 16px 5px 16px;
        font-size: 14px;
        font-weight: 700;
        height: auto;
        &.btn-continue {
            background-color: #0161af;
            color: #fff;
        }
    }
    .ant-select {
        width: 100%;
    }
    input,
    .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
        border-radius: 23px;
        padding: 8px 16px 8px 16px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 36px;
    }

    .all-selection-group {
        display: flex;
        gap: 10px;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 32px;
        border-bottom: 1px solid #cccccc;
        label{
            font-size: 14px;
            color: #4A4A4A;
        }
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
        line-height:1.4;
    }
}

.delivery-selection {
    .options-head {
        display: flex;
        gap: 32px;
        margin-bottom: 8px;
        span {
            flex: 1;
            font-size: 12px;
            font-weight: 700;
        }
    }
    .title {
        font-size: 14px;
        text-transform: none;
        text-align: left;
        margin-bottom: 16px;
        font-weight: 400;
        color: #4A4A4A;
    }
    .ant-radio-group {
        display: flex;
        gap: 25px;
        .ant-radio-button-wrapper {
            flex: 1;
            height: auto;
            padding: 16px;
            line-height: 1;
            border-radius: 10px !important;
            border-left-width: unset;
            border-top-width: unset;
            text-align: center;
            * {
                box-shadow: none;
                outline: none;
            }
            &::before {
                display: none;
            }
            span {
                justify-content: center;
                font-size: 14px;
                color: #333333;
                font-weight: 700;
            }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            color: #0161af;
            border-color: #0161af;
            border-width: 3px;
            span {
                color: #0161af;
                &::before {
                    content: "";
                    position: absolute;
                    width: 23px;
                    height: 24px;
                    background-image: url(${CheckDelivery});
                    top: 0;
                    margin-top: -12px;
                    right: 0;
                    margin-right: -12px;
                    background-size: 100%;
                }
            }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
            outline: none;
            box-shadow: none;
        }
    }
}

.license-plate-option {
    .ant-switch-checked {
        background-color: #0161af;
    }
    .ant-select {
        flex: 1;
    }
    .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
        border-radius: 23px;
        padding: 8px 16px 8px 16px;
        width: 100%;
    }
    .gap-row {
        gap: 32px;
    }
    .title {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 16px;
        span {
            font-weight: 400;
        }
    }
    .obs {
        font-size: 12px;
    }
}
.selected-option {
    background-color: #f1f1f1;
    padding: 32px;
    border-radius: 16px;
    margin-top: 32px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        height: 1px;
        background: #ccc;
        top: 0;
        margin-top: -16px;
        left: 0;
        width: 100%;
    }
    label {
        display: block;
    }

    .ant-select {
        width: 100%;
    }
    input,
    .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
        border-radius: 23px;
        padding: 8px 16px 8px 16px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 36px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
        line-height:1.4;
    }
    .location-item{
        padding: 24px;
        border: 2px solid transparent;
        cursor: pointer;
        position: relative;
        &::after{
            content:'';
            width: 100%;
            height: 1px; background-color: #C2C2C2;
            position: absolute;
            left: 0;
            bottom: 0;
            margin-bottom: -4px;
        }
        .name{
            font-weight: 700;
        }
        &.active{
            border-radius: 8px;
            background-color: #fff;
            border: 2px solid #0161AF;
            background-image: url(${CheckDelivery});
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: 98% center;
            &::after{
                display: none;
            }
            .name{
                color: #0161AF;
            }
        }
    }
}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 40px auto;
    align-items: center;
    font-family: "Open Sans";
    border-bottom: 1px solid #707070;
    padding-bottom: 16px;
    width: 100%;

    p {
        color: #0a0a0a;
    }

    img {
        margin-right: 20px;
        width: 192px;
        height: fit-content;
    }
`;

export const Panel = styled.div`
    .car-infos {
        display: flex;
    }
`;

export const DetailsCar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .info-car {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        .wrapper-car {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 20px;
        }

        .car-name {
            font-size: 22px;
            margin-top: -10px;
            margin: 0;
        }

        .details {
            color: #797979;
            font-weight: 600;
            margin: 0;
            font-size: 16px;
        }

        .optionals {
            color: #797979;
            font-weight: 600;
            margin: 0;
            font-size: 12px;
        }

        .info-offer {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .options,
            .button {
                margin-right: 17px;
            }

            .options,
            .conductor {
                p {
                    margin-bottom: 6px;
                }
            }

            .dealership {
                height: 129px;
                margin-left: 30px;

                .margin {
                    margin-bottom: 8px;
                    color: #005faf;
                    font-weight: bold;
                    font-size: 16px;
                }

                .wrapper-dealership {
                    p {
                        margin: 0;
                    }

                    .wrapper-checkbox {
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;

                        input {
                            cursor: pointer;
                            margin-right: 6px;
                        }
                    }
                }

                .state {
                    margin-right: 18px;
                }

                .wrapper-content {
                    display: flex;
                    flex-wrap: wrap;

                    p {
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }

    .conductor {
        color: #070707;
        margin-right: 18px;

        p {
            padding: 0;
        }
        img {
            margin-right: 5px;
            border-radius: 50%;
            width: 33px;
            height: 33px;
            padding: 2px;
        }
    }

    @media (max-width: 500px) {
        .info-car {
            .car-name,
            .details {
                text-align: center;
            }

            .car-name {
                font-size: 16px;
            }

            .dealership {
                height: 190px !important;
                margin-top: 12px;

                .wrapper-content {
                    .city {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
`;
