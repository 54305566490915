import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  padding: 30px 0;
`;

export const StyledBarContainer = styled.div``;

export const StyledBar = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  border-radius: 40px;
  height: 12px;
  width: 100%;
`;

export const StyledIndicator = styled.div`
  border: 3px solid #3c505a;
  border-radius: 50%;
  background-color: #fff;
  height: 24px;
  width: 24px;
  position: absolute;
  top: -32px;
`;

export const StyledScale = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  & > div {
    &:first-child {
      justify-content: start;
    }
    &:last-child {
      justify-content: end;
      & > div {
        margin-right: -2px;
      }
    }
  }
`;

export const StyledScaleItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledScaleValue = styled.div`
  color: #9e9e9e;
  font-size: 14px;
`;

export const StyledDescription = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  height: 22px;
  text-align: center;
  margin-top: 10px;
`;
