import { Button, Checkbox, Collapse, InputNumber, Select, Skeleton } from "antd";
import Footer from "components/Footer";
import Header from "components/Header";
import React, { useState, useCallback, useEffect, useRef } from "react";
import api, { defaultBackendUrl } from "services/api";
import { ConfigOfferContent, PageHead, WrapperImage } from "./styles";
import ImgWithFallback from "components/ImgWithFallback";
import noImageCar from "assets/defaultImages/no-image.png";
import formatValue from "utils/formatValue";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import { getAudiFilters } from "services/offers";
import OrderResume from "../../components/orderResume";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import ProposalPDF from "../../components/ProposalPDF";
import { useAuth } from "hooks/auth";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useToast } from "hooks/toast";
const { Panel } = Collapse;
const { Option } = Select;
const ConfigOfferPJ: React.FC<any> = () => {
    const history = useHistory();
    const { fullname, dealershipId, userId } = useAuth();
    const hiddenContainerRef = useRef<HTMLDivElement>(null);
    const [productsList, setProductsList] = useState<any[]>(JSON.parse(localStorage.getItem("@FleetSolutions:cart")!) || []);
    const [segment, setSegment] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:currentSelectedSegment")!) || null);
    const [isLoading, setIsLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [showPDFComponent, setShowPDFComponent] = useState(false);
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const { addToast } = useToast();
    const [customOptionalsProducts, setCustomOptionalsProducts] = useState<any[]>([] as any);
    const selectOptionalsRef = useRef<any>();
    const selectAccessoriesRef = useRef<any>();
    const [nameDealership, setNameDealership] = useState("");
    const menuItems = [
        {
            label: "Meus Pedidos",
            url: "/my-orders",
        },
        {
            label: "Novo Pedido",
            url: `/ofertas/pj/${segment?.iD_Fleet}`,
        },
        {
            label: "Configuração do Pedido",
        },
    ];
    useEffect(() => {
        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;
            setNameDealership(data.name);
        });
    }, [dealershipId]);
    useEffect(() => {
        const fetchData = async () => {
            if (initialLoad && productsList.length > 0) {
                const updatedProductsList = await Promise.all(
                    productsList.map(async (product) => {
                        if (!product.productTemplate) {
                            try {
                                const res = await api.get(`/offers/${product.productId}`);
                                let data = res.data;
                                const parameters = {
                                    deadline: data.deadline,
                                    monthlyKmValue: data.monthlyKmValue,
                                    modelCode: data.modelCode,
                                    color: data.color,
                                    kickback: data.kickback,
                                    segmentId: data.segment,
                                };
                                product.parameters = parameters;
                                product.productTemplate = {
                                    ...data,
                                    stockQuantity: data.stockQuantity,
                                };
                                if (data.stockQuantity === 0) {
                                    const alternativeResponse = await api.get(`offers?modelCode=${parameters.modelCode}&color=${parameters.color}&deadline=${parameters.deadline}&segmentId=${parameters.segmentId}`);
                                    if (alternativeResponse.data.length) {
                                        const offerWithStock = alternativeResponse.data.filter((item: { stockQuantity: number }) => item.stockQuantity > 0).sort((a: { monthlyInstallment: number }, b: { monthlyInstallment: number }) => a.monthlyInstallment - b.monthlyInstallment)[0];
                                        if (offerWithStock) {
                                            const alternativeOfferRes = await api.get(`/offers/${offerWithStock.productId}`);
                                            const alternativeData = alternativeOfferRes.data;
                                            product.productId = offerWithStock.productId;
                                            product.parameters = {
                                                ...product.parameters,
                                                deadline: alternativeData.deadline,
                                                monthlyKmValue: alternativeData.monthlyKmValue,
                                                modelCode: alternativeData.modelCode,
                                                color: alternativeData.color,
                                                kickback: alternativeData.kickback,
                                                segmentId: alternativeData.segment,
                                            };
                                            product.productTemplate = {
                                                ...alternativeData,
                                                stockQuantity: alternativeData.stockQuantity,
                                            };
                                        }
                                    }
                                }
                                const response = await api.get(`offers?modelCode=${parameters.modelCode}&color=${parameters.color}&deadline=${parameters.deadline}&segmentId=${parameters.segmentId}`);
                                if (response.data.length) {
                                    const uniqueKmValues = Array.from(new Set(response.data.map((item: any) => item.monthlyKmValue)));
                                    product.monthlyKmValues = uniqueKmValues.map((kmValue) => ({
                                        monthlyKmValue: kmValue,
                                    }));
                                    const correctStock = response.data.find((item: { productId: any }) => item.productId === product.productId)?.stockQuantity;
                                    data.stockQuantity = correctStock;
                                }
                                return { ...product, productTemplate: data, parameters: product.parameters };
                            } catch (error) {
                                console.error("Erro ao buscar oferta para o produto:", error);
                                return product;
                            }
                        }
                        if (segment?.personalizedJourney) {
                            handleProductsWithExtras(product, true, product.productTemplate?.stockQuantity);
                        }
                        return product;
                    })
                );
                setProductsList(updatedProductsList);
                localStorage.setItem("@FleetSolutions:cart", JSON.stringify(updatedProductsList));
                setIsLoading(false);
                setInitialLoad(false);
            }
        };
        fetchData();
    }, [initialLoad, productsList]);
    const handleProductsList = async (index?: number, type?: string, value?: any) => {
        setIsLoading(true);
        if (typeof index === "number") {
            let currentItem = { ...productsList[index] };
            if (currentItem) {
                const updatedItem = {
                    ...currentItem,
                    parameters: {
                        ...currentItem.parameters,
                        deadline: type === "deadline" ? value : currentItem.parameters.deadline,
                        monthlyKmValue: type === "monthlyKm" ? value : currentItem.parameters.monthlyKmValue,
                        kickback: type === "kickback" ? value : currentItem.parameters.kickback,
                    },
                };
                try {
                    let url = `offers?modelCode=${updatedItem.parameters.modelCode}&color=${updatedItem.parameters.color}&deadline=${updatedItem.parameters.deadline}&segmentId=${updatedItem.parameters.segmentId}`;
                    if (type === "monthlyKm" && updatedItem.parameters.monthlyKmValue) {
                        url += `&monthlyKmValue=${updatedItem.parameters.monthlyKmValue}`;
                    }
                    if (type === "kickback" && updatedItem.parameters.kickback) {
                        url += `&kickback=${updatedItem.parameters.kickback}`;
                    }
                    const response = await api.get(url);
                    if (response.data.length) {
                        const validOffers = response.data.filter((item: { stockQuantity: number }) => item.stockQuantity > 0);
                        if (validOffers.length > 0) {
                            const selectedOffer = validOffers[0];
                            updatedItem.productTemplate = selectedOffer;
                            updatedItem.productId = selectedOffer.productId;
                            updatedItem.parameters = {
                                ...updatedItem.parameters,
                                deadline: selectedOffer.deadline,
                                monthlyKmValue: selectedOffer.monthlyKmValue,
                                modelCode: selectedOffer.modelCode,
                                color: selectedOffer.color,
                                kickback: selectedOffer.kickback,
                                segmentId: selectedOffer.segment,
                            };
                            if (type === "deadline") {
                                const lista: string[] = [];
                                response.data.forEach((elemento: any) => {
                                    if (!lista.includes(elemento.monthlyKmValue)) {
                                        lista.push(elemento.monthlyKmValue);
                                    }
                                });
                                updatedItem.monthlyKmValues = lista.map((dataItem: any) => ({
                                    monthlyKmValue: dataItem,
                                }));
                            }
                            const updatedProductsList = [...productsList];
                            updatedProductsList[index] = { ...updatedItem };
                            setProductsList(updatedProductsList);
                            localStorage.setItem("@FleetSolutions:cart", JSON.stringify(updatedProductsList));
                            if (segment?.personalizedJourney) {
                                handleProductsWithExtras(updatedProductsList[index], false, updatedProductsList[index]?.productTemplate?.stockQuantity);
                            }
                        } else {
                            addToast({
                                title: "Atenção!",
                                type: "error",
                                description: "Nenhuma oferta disponível com estoque para esses parâmetros. Os últimos parâmetros selecionados foram mantidos",
                            });
                        }
                    } else {
                        addToast({
                            title: "Atenção!",
                            type: "error",
                            description: "Nenhuma oferta disponível com estoque para esses parâmetros. Os últimos parâmetros selecionados foram mantidos",
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.error("Erro ao buscar oferta para o produto:", error);
                    addToast({
                        title: "Atenção!",
                        type: "error",
                        description: "Nenhuma oferta disponível com estoque para esses parâmetros. Os últimos parâmetros selecionados foram mantidos",
                    });
                    setIsLoading(false);
                }
            } else {
                console.error("Produto não encontrado no índice especificado");
            }
        }
    };
    const handleProductQuantity = (index: number, value: any) => {
        const updatedProductsList = [...productsList];
        updatedProductsList[index] = {
            ...updatedProductsList[index],
            quantity: value,
        };
        setProductsList(updatedProductsList);
        localStorage.setItem("@FleetSolutions:cart", JSON.stringify(updatedProductsList));
    };
    const handleProductsWithExtras = (product: any, firstLoad: boolean, stock?: number, isRemoval?: boolean, removedValue?: string) => {
        console.log(product);
        const updatedProductsList = [...productsList];
        const productIndex = updatedProductsList.findIndex((item) => item.id === product.id);
        if (productIndex === -1) {
            console.error("Produto não encontrado na lista de produtos.");
            setIsLoading(false);
            return;
        }
        let currentItem = { ...productsList[productIndex] };
        if (!product.parameters) {
            console.error("Parâmetros do produto estão indefinidos.");
            setIsLoading(false);
            return;
        }
        const params: any = {
            modelCode: product.parameters.modelCode ? Number(product.parameters.modelCode) : null,
            color: product.parameters.color || null,
            deadLine: product.parameters.deadline || null,
            monthlyKmValue: product.parameters.monthlyKmValue || null,
            segmentId: Number(localStorage.getItem("@FleetSolutions:segmentId")) || null,
            listAccessories: product.parameters.extraAccessories || [],
            listOptional: segment.iD_Fleet !== 27 ? product.parameters.extraOptionals || [] : [],
            kickback: product.parameters.kickback || null,
        };
        getAudiFilters(params)
            .then(({ data }: { data: any }) => {
                if (data.products.length > 0) {
                    if (isRemoval) {
                        const firstProductOptional = data.products[0]?.optional || "";
                        const accessoryExists = firstProductOptional.includes(removedValue);
                        if (accessoryExists) {
                            const { optValues, acsValues } = transformStringToArrays(data.products[0].optional);
                            updatedProductsList[productIndex].parameters.extraAccessories = acsValues || [];
                            updatedProductsList[productIndex].parameters.extraOptionals = optValues || [];
                            setProductsList(updatedProductsList);
                            addToast({
                                title: "Atenção!",
                                type: "error",
                                description: "Nenhuma oferta disponível sem o item selecionado. O item foi mantido.",
                            });
                            setIsLoading(false);
                            return;
                        }
                    }
                    if (firstLoad) {
                        updatedProductsList[productIndex].extraAccessories = data.accessories;
                        updatedProductsList[productIndex].extraOptionals = data.optionals;
                        const { optValues, acsValues } = transformStringToArrays(data.products[0].optional);
                        updatedProductsList[productIndex].parameters.extraAccessories = acsValues || [];
                        updatedProductsList[productIndex].parameters.extraOptionals = optValues || [];
                        updatedProductsList[productIndex].productTemplate = {
                            ...data.products[0],
                        };
                        if (stock) {
                            updatedProductsList[productIndex].productTemplate.stockQuantity = stock;
                        }
                    } else {
                        updatedProductsList[productIndex].productTemplate = {
                            ...updatedProductsList[productIndex].productTemplate,
                            ...data.products[0],
                        };
                        if (stock) {
                            updatedProductsList[productIndex].productTemplate.stockQuantity = stock;
                        }
                        updatedProductsList[productIndex].extraAccessories = data.accessories;
                        updatedProductsList[productIndex].extraOptionals = data.optionals;
                        const { optValues, acsValues } = transformStringToArrays(data.products[0].optional);
                        updatedProductsList[productIndex].parameters.extraAccessories = acsValues || [];
                        updatedProductsList[productIndex].parameters.extraOptionals = optValues || [];
                        updatedProductsList[productIndex].parameters.monthlyKmValue = data.products[0].monthlyKmValue;
                        updatedProductsList[productIndex].parameters.deadline = data.products[0].deadline;
                        updatedProductsList[productIndex].parameters.kickback = data.products[0].kickback;
                        updatedProductsList[productIndex].productId = data.products[0].productId;
                    }
                    updatedProductsList[productIndex].monthlyKmValues = product.monthlyKmValues;
                    updatedProductsList[productIndex].customOptionalsProducts = data.products;
                } else {
                    addToast({
                        title: "Atenção!",
                        type: "error",
                        description: "Nenhuma oferta disponível com estoque para esses parâmetros. O último acessório/opcional foi removido.",
                    });
                    if (params.listAccessories && params.listAccessories.length > 0) {
                        updatedProductsList[productIndex].parameters.extraAccessories.pop();
                    } else if (params.listOptional && params.listOptional.length > 0) {
                        updatedProductsList[productIndex].parameters.extraOptionals.pop();
                    }
                }
                setProductsList(updatedProductsList);
                localStorage.setItem("@FleetSolutions:cart", JSON.stringify(updatedProductsList));
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Erro ao obter filtros adicionais:", error);
                setIsLoading(false);
            });
    };
    const transformStringToArrays = (inputString: string) => {
        const items = inputString.split("|").filter(Boolean);
        const optValues: string[] = [];
        const acsValues: string[] = [];
        items.forEach((item) => {
            if (item.startsWith("opt=")) {
                optValues.push(item.replace("opt=", "").replace(";", "").trim());
            } else if (item.startsWith("acs=")) {
                acsValues.push(item.replace("acs=", "").replace(";", "").trim());
            }
        });
        return { optValues, acsValues };
    };
    const handleSelectExtraItems = (product: any, value: any, type: any) => {
        if (type === "OPTIONAL") {
            selectOptionalsRef?.current?.blur();
            product.parameters.extraOptionals.push(value);
        } else {
            selectAccessoriesRef?.current?.blur();
            product.parameters.extraAccessories.push(value);
        }
        handleProductsWithExtras(product, false);
    };
    const handleDeselectExtraItems = (product: any, value: any, type: any) => {
        if (type === "OPTIONAL") {
            selectOptionalsRef?.current?.blur();
            product.parameters.extraOptionals = product.parameters.extraOptionals.filter((item: any) => item !== value);
        } else {
            selectAccessoriesRef?.current?.blur();
            product.parameters.extraAccessories = product.parameters.extraAccessories.filter((item: any) => item !== value);
        }
        handleProductsWithExtras(product, false, undefined, true, value);
    };
    const handleCustomOptionals = async (value: any, itemIndex: number) => {
        const updatedProductsList = [...productsList];
        const currentProduct = updatedProductsList[itemIndex];
        updatedProductsList[itemIndex].productTemplate = {
            ...updatedProductsList[itemIndex].productTemplate,
            ...value,
        };
        updatedProductsList[itemIndex].parameters.monthlyKmValue = value.monthlyKmValue;
        updatedProductsList[itemIndex].parameters.deadline = value.deadline;
        updatedProductsList[itemIndex].parameters.kickback = value.kickback;
        updatedProductsList[itemIndex].productId = value.productId;
        updatedProductsList[itemIndex].productTemplate = value;
        setProductsList(updatedProductsList);
    };
    const handleClear = (product: any) => {
        const updatedProducts = productsList.filter((productItem) => productItem.id !== product?.id);
        setProductsList(updatedProducts);
        localStorage.setItem("@FleetSolutions:cart", JSON.stringify(updatedProducts));
    };
    const CustomPanel = ({ header, onClear }: any) => {
        const handleClearClick = (e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onClear();
        };
        return (
            <div className="custom-header">
                <span>{header}</span>
                <div className="action">
                    <Button type="text" onClick={handleClearClick} className="clear-button">
                        <img src={deleteIcon} alt="Remover do carrinho" />
                        Remover do carrinho
                    </Button>
                </div>
            </div>
        );
    };
    const handleContinue = () => {
        history.push("/checkout-pedido");
    };
    const handleBack = () => {
        history.goBack();
    };
    const proposalDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear().toString();
        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");
        const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}`;
        return formattedDateTime;
    };
    const handleGeneratePdf = () => {
        setShowPDFComponent(true);
        setGeneratingPDF(true);
        setTimeout(() => {
            if (hiddenContainerRef.current) {
                html2canvas(hiddenContainerRef.current, {
                    scale: 1,
                    useCORS: true,
                }).then((canvas) => {
                    const contentWidth = canvas.width;
                    const contentHeight = canvas.height;
                    const pdfWidth = contentWidth;
                    const pdfHeight = contentHeight;
                    const doc: any = new jsPDF({
                        orientation: "portrait",
                        unit: "pt",
                        format: [pdfWidth, pdfHeight],
                    });
                    doc.setPrecision(2);
                    doc.setFont("Open Sans", "normal");
                    doc.html(hiddenContainerRef.current as HTMLElement, {
                        x: 0,
                        y: 0,
                        html2canvas: {
                            scale: 1,
                            useCORS: true,
                        },
                        callback: function (doc: any) {
                            const totalPages = doc?.internal?.getNumberOfPages();
                            if (totalPages > 1) {
                                doc.deletePage(totalPages);
                            }
                            setTimeout(() => {
                                doc.save("Proposta_Comercial_Canal_Indireto.pdf");
                            }, 500);
                            setShowPDFComponent(false);
                            setGeneratingPDF(false);
                        },
                    });
                });
            }
        }, 1000);
    };
    return (
        <>
            <Header />
            {showPDFComponent && (
                <div ref={hiddenContainerRef} style={{ position: "fixed", zIndex: "-9999999999", overflow: "hidden", top: 0 }}>
                    <ProposalPDF
                        parameters={{
                            productsList: productsList,
                            seller: fullname,
                            segmentSummary: segment?.orderSummary,
                            proposalDate: proposalDate(),
                            segment: segment,
                            dealer: nameDealership,
                        }}
                    />
                </div>
            )}
            <Breadcrumb menuItems={menuItems} />
            <ConfigOfferContent>
                <PageHead>
                    <div className="container-xxl">
                        <div className="row">
                            <div className="col">
                                <div className="configs-head">
                                    <h1>Configuração do Pedido</h1>
                                    <Button loading={generatingPDF} onClick={() => handleGeneratePdf()} className="btn-print">
                                        <div className="print">
                                            <DownloadOutlined color="#236FBC" size={30} /> Exportar PDF da Proposta
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageHead>
                {productsList.length > 0 && (
                    <div className="container-xxl">
                        <Collapse defaultActiveKey={0}>
                            {productsList?.map((product: any, index: number) => (
                                <Panel header={<CustomPanel header={product?.model} onClear={() => handleClear(product)} />} key={index}>
                                    <div className="offer-content">
                                        <div className="configs">
                                            <div className="image">
                                                <WrapperImage>
                                                    <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product?.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product?.modelCode}.png`, noImageCar]} alt={product?.model} />
                                                </WrapperImage>
                                            </div>
                                            <div className="form">
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <label>Cor</label>
                                                        <Select loading={isLoading} disabled={isLoading} defaultValue={product.color}>
                                                            {product.productWithColor &&
                                                                product.productWithColor
                                                                    .sort((a: any, b: any) => {
                                                                        if (a.color < b.color) {
                                                                            return -1;
                                                                        }
                                                                        if (a.color > b.color) {
                                                                            return 1;
                                                                        }
                                                                        return 0;
                                                                    })
                                                                    .map((subItem: any, idx: number) => (
                                                                        <Option key={idx} value={subItem.color}>
                                                                            {subItem.color}
                                                                        </Option>
                                                                    ))}
                                                        </Select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Prazo</label>
                                                        <Select loading={isLoading} disabled={isLoading} onChange={(value) => handleProductsList(index, "deadline", value)} value={product?.parameters?.deadline || "Selecione"}>
                                                            {product.deadlines &&
                                                                product.deadlines
                                                                    .sort((a: any, b: any) => {
                                                                        if (a.deadline < b.deadline) {
                                                                            return -1;
                                                                        }
                                                                        if (a.deadline > b.deadline) {
                                                                            return 1;
                                                                        }
                                                                        return 0;
                                                                    })
                                                                    .map((subItem: any, idx: number) => (
                                                                        <Option key={idx} value={subItem.deadline}>
                                                                            {subItem.deadline} meses
                                                                        </Option>
                                                                    ))}
                                                        </Select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Franquia Mensal</label>
                                                        <Select loading={isLoading} disabled={isLoading} onChange={(value) => handleProductsList(index, "monthlyKm", value)} value={product?.parameters?.monthlyKmValue || "Selecione"}>
                                                            {product.monthlyKmValues &&
                                                                product.monthlyKmValues
                                                                    .sort((a: any, b: any) => {
                                                                        if (a.monthlyKmValue < b.monthlyKmValue) {
                                                                            return -1;
                                                                        }
                                                                        if (a.monthlyKmValue > b.monthlyKmValue) {
                                                                            return 1;
                                                                        }
                                                                        return 0;
                                                                    })
                                                                    .map((subItem: any, idx: number) => (
                                                                        <Option key={idx} value={subItem.monthlyKmValue}>
                                                                            {subItem.monthlyKmValue} Km
                                                                        </Option>
                                                                    ))}
                                                        </Select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Comissão de Venda</label>
                                                        <Select loading={isLoading} disabled={isLoading} onChange={(value) => handleProductsList(index, "kickback", value)} value={product?.parameters?.kickback || "Selecione"}>
                                                            {product.kickback &&
                                                                product.kickback
                                                                    .sort((a: any, b: any) => {
                                                                        if (a.kickback < b.kickback) {
                                                                            return -1;
                                                                        }
                                                                        if (a.kickback > b.kickback) {
                                                                            return 1;
                                                                        }
                                                                        return 0;
                                                                    })
                                                                    .map((subItem: any, idx: number) => (
                                                                        <Option key={idx} value={subItem.kickback}>
                                                                            {subItem.kickback}
                                                                        </Option>
                                                                    ))}
                                                        </Select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Quantidade</label>
                                                        <InputNumber disabled={isLoading} min={1} max={product?.productTemplate?.stockQuantity || 1} defaultValue={product?.quantity} onChange={(value) => handleProductQuantity(index, value)} />
                                                    </div>
                                                </div>
                                                <div className={segment?.iD_Fleet === 27 ? "form-row custom-form-row" : "form-row"}>
                                                    {segment?.iD_Fleet === 27 ? (
                                                        <>
                                                            {product?.extraOptionals?.length > 0 && (
                                                                <div className="form-group">
                                                                    <label>Pacotes de Opcionais</label>
                                                                    <div className="custom-optionals">
                                                                        {product?.customOptionalsProducts
                                                                            ?.filter((itemOptFilter: any) => {
                                                                                const hasSelectedAccessory = product?.parameters?.extraAccessories && product?.parameters?.extraAccessories.length;
                                                                                return hasSelectedAccessory ? itemOptFilter.optional.toLowerCase().includes("blindagem") : !itemOptFilter.optional.toLowerCase().includes("blindagem");
                                                                            })
                                                                            .map((itemMap: any, idxOpt: any) => {
                                                                                const formattedText = "- " + itemMap.optionalText.replace(/ ; /g, " ;<br/>- ");
                                                                                return (
                                                                                    <div key={`item` + idxOpt} onClick={() => handleCustomOptionals(itemMap, index)} className={product?.productTemplate?.productId === itemMap.productId ? "optional-group selected" : "optional-group"}>
                                                                                        <div className="head-group">
                                                                                            <Checkbox checked={product?.productTemplate?.productId === itemMap.productId}></Checkbox>
                                                                                            <h4>Pacote de opcionais {idxOpt + 1}</h4>
                                                                                        </div>
                                                                                        <span dangerouslySetInnerHTML={{ __html: formattedText }} />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {product?.extraOptionals && product?.extraOptionals?.length > 0 && (
                                                                <div className="form-group">
                                                                    <label>Opcionais</label>
                                                                    <Select
                                                                        loading={isLoading}
                                                                        disabled={isLoading}
                                                                        ref={selectOptionalsRef}
                                                                        mode="multiple"
                                                                        value={product?.parameters?.extraOptionals || []}
                                                                        placeholder="Selecione os opcionais"
                                                                        onDeselect={(value) => handleDeselectExtraItems(product, value, "OPTIONAL")}
                                                                        onSelect={(value) => handleSelectExtraItems(product, value, "OPTIONAL")}
                                                                    >
                                                                        {product?.extraOptionals &&
                                                                            product?.extraOptionals
                                                                                .sort((a: any, b: any) => {
                                                                                    if (a.name < b.name) {
                                                                                        return -1;
                                                                                    }
                                                                                    if (a.name > b.name) {
                                                                                        return 1;
                                                                                    }
                                                                                    return 0;
                                                                                })
                                                                                .map((subItem: any, idx: number) => (
                                                                                    <Option key={idx} value={subItem.name}>
                                                                                        {subItem.name}
                                                                                    </Option>
                                                                                ))}
                                                                    </Select>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {product?.extraAccessories && product?.extraAccessories?.length > 0 && (
                                                        <div className="form-group">
                                                            <label>Acessórios</label>
                                                            <Select
                                                                loading={isLoading}
                                                                disabled={isLoading}
                                                                ref={selectAccessoriesRef}
                                                                mode="multiple"
                                                                value={product?.parameters?.extraAccessories || []}
                                                                placeholder="Selecione os acessórios"
                                                                onDeselect={(value) => handleDeselectExtraItems(product, value, "ACCESSORIES")}
                                                                onSelect={(value) => handleSelectExtraItems(product, value, "ACCESSORIES")}
                                                            >
                                                                {product?.extraAccessories &&
                                                                    product?.extraAccessories
                                                                        .sort((a: any, b: any) => {
                                                                            if (a.name < b.name) {
                                                                                return -1;
                                                                            }
                                                                            if (a.name > b.name) {
                                                                                return 1;
                                                                            }
                                                                            return 0;
                                                                        })
                                                                        .map((subItem: any, idx: number) => (
                                                                            <Option key={idx} value={subItem.name}>
                                                                                {subItem.name}
                                                                            </Option>
                                                                        ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prices">
                                            <div className="price-item">
                                                <div className="unity-price">
                                                    <label>Valor Unitário: </label>
                                                    <div className="price-wrapper">
                                                        {isLoading && <Skeleton.Button style={{ width: "100%", height: "28px", display: "block", marginBottom: "0", position: "absolute", top: "0" }} active />}
                                                        <div style={{ opacity: isLoading ? 0 : 1 }}>
                                                            {formatValue(product?.productTemplate?.monthlyInstallment)}
                                                            <span> / por mês</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="price-item last">
                                                <div className="total-price">
                                                    <label>Total: </label>
                                                    <div className="price-wrapper">
                                                        {isLoading && <Skeleton.Button style={{ width: "100%", height: "28px", display: "block", marginBottom: "0", position: "absolute", top: "0" }} active />}
                                                        <div style={{ opacity: isLoading ? 0 : 1 }}>
                                                            {formatValue(product?.productTemplate?.monthlyInstallment * product?.quantity)}
                                                            <span> / por mês</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="offer-infos">
                                            <div className="offer-items">
                                                <div className="title">Opcionais:</div>
                                                <p>{product?.productTemplate?.optionalText || "-"}</p>
                                            </div>
                                            <div className="offer-items">
                                                <div className="title">Acessórios:</div>
                                                <p>{product?.productTemplate?.accessoryText?.toLowerCase() || "-"}</p>
                                            </div>
                                        </div>
                                        <div className="contract-infos">
                                            <div className="item">
                                                <div className="title">Custo/ KM Excedente:</div>
                                                {formatValue(product?.productTemplate?.overrunKm)}
                                            </div>
                                            <div className="item">
                                                <div className="title">Coparticipação em sinistro:</div>
                                                5% da tabela FIPE
                                            </div>
                                            <div className="item">
                                                <div className="title">Prazo de entrega:</div>
                                                {product?.productTemplate?.deadlineInfo}
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            ))}
                        </Collapse>
                        <div className="row">
                            <div className="col">
                                <OrderResume productsList={productsList} />
                            </div>
                        </div>
                    </div>
                )}
                <div className="container-xxl">
                    <div className="row">
                        <div className="col">
                            <div className="buttons">
                                <div className="wrapper-btn">
                                    <Button onClick={handleBack} className="btn-back">
                                        Voltar
                                    </Button>
                                    <Button className="btn-continue" onClick={handleContinue}>
                                        Continuar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ConfigOfferContent>
            <Footer />
        </>
    );
};
export default ConfigOfferPJ;
