import styled from 'styled-components'

export const UploadsContent = styled.div`
  h2{
    color: #333333;
    font-size: 16px;
    line-height:16px;
    font-weight:700;
  }
  span.subtitle{
    color: #333333;
    font-size: 14px;
    line-height:14px;
    font-weight:400;
    position: relative;
    margin-bottom: 32px;
    display: block;
  }
  .instructions{
    color: #666666;
    font-size: 12px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px 16px;
  margin-bottom: 32px;
`;

