/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Select, Radio, Collapse, Button, RadioChangeEvent, Input, Switch, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Content, DetailsCar } from "./styles";
// import Button from '../../components/Button'

import api, { defaultBackendUrl } from "services/api";
import noImageCar from "assets/defaultImages/no-image.png";
// import "../../styles/bootstrap-grid.css";
import ClearIcon from "../../../../../../assets/icons/backspace.svg";
import PaginationList from "components/PaginationList";
import ImgWithFallback from "components/ImgWithFallback";
import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";

import { IState } from "store";
import { ILocation } from "store/modules/location/types";
import { setTabs } from "store/modules/tabsRegisterUser/actions";
import { listProducts } from "utils";
import * as Segment from "../../../../../../utils/segment";
import { IMaskInput } from "react-imask";

const { Panel } = Collapse;

export interface Estado {
    id: number;
    sigla: string;
    nome: string;
    regiao?: Estado;
}

export interface Cidade {
    id: number;
    nome: string;
    microrregiao: Microrregiao;
    "regiao-imediata": RegiaoImediata;
}

export interface Microrregiao {
    id: number;
    nome: string;
    mesorregiao: Mesorregiao;
}

export interface Mesorregiao {
    id: number;
    nome: string;
    UF: Uf;
}

export interface Uf {
    id: number;
    sigla: string;
    nome: string;
    regiao?: Uf;
}

export interface RegiaoImediata {
    id: number;
    nome: string;
    "regiao-intermediaria": Mesorregiao;
}

interface iPropsProducts {
    color: string;
    productId: number;
    quantity: number;
    modelCode: number;
    model: string;
    optional: string;
    deadline: number;
    monthlyKmValue: number;
    monthlyInstallment: number;
    hullFranchiseValue: string;
    overrunKm: number;
    defaultValueCity: string;
    id: number;
    stateForPickup: string;
    totalRequestCredit: number;
    isDNPickupLocation: string;
    defaultOptional: string;
}

interface IProductsOrder {
    quantity: number;
    productId: number;
    cityForPickup: string;
    uf: string;
    id: number;
    optional: string;
}

interface IDealership {
    name: string;
    street: string;
    addressNumber: string;
}

export interface OrderITem {
    productId: number;
    finalPlate: any | null;
    deliveryOption: number | null;
    cityforPickup: any;
    uf: any;
    address: Address | null;
    canChosseLicensePlate: boolean;
    willChosseLicensePlate: boolean;
    licensePlate: string | null | any[] | any;
    dealer?: number | null;
    dealerInfos?: any | null;
    // dealer?: string | null;
    city?: string | null;
    locationsOptions?: any[] | any;
    ufId?: number | null;
}

export interface Address {
    zipCode?: string | null;
    state?: string | null;
    city?: string | null;
    district?: string | null;
    street?: string | null;
    number?: string | null;
    complement?: string | null;
    siglaUF?: string | null;
}

const PickupLocationPJ = ({ productsList, setProductsList }:any) => {
    const [perPage] = useState(5);
    const [total, setTotal] = useState(0);
    const [statesBr, setStatesBr] = useState<any>([]);

    const [productsData, setProductsData] = useState<iPropsProducts[]>([]);
    const [allProducts, setAllProducts] = useState<iPropsProducts[]>([]);

    const [products, setProducts] = useState<IProductsOrder[]>([]);
    const [dealershipAudi, setDealershipAudi] = useState(false);
    const [dealership, setDealership] = useState<IDealership>();

    const [updateProducts, setUpdateProducts] = useState(true);
    const tabId = useSelector<IState, ILocation>((state) => state.tabs);
    const segmentId = localStorage.getItem("@FleetSolutions:segmentId");

    const [canSelectLicensePlate, setCanSelectLicensePlate] = useState<boolean>(false);

    const [states, setStates] = useState<Estado[]>([]);

    const [allOptionsValue, setAllOptionsValue] = useState<any>();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentUf, setCurrentUf] = useState<any>();
    const [currentCity, setCurrentCity] = useState<any>();
    const [currentAddress, setCurrentAddress] = React.useState<any>({
        zipCode: "",
        street: "",
        number: "",
        district: "",
        complement: "",
        city: "",
        state: "",
        canChosseLicensePlate: false,
    });

    const [orderItems, setOrderItems] = React.useState<OrderITem[]>([]);
    const [orderItemsCities, setOrderItemsCities] = React.useState<any[]>([]);

    const [deliveryOptions, setDeliveryOptions] = useState<any>([] as any);

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalRecords, setTotalRecords] = useState<any>(0);
    const [data, setData] = useState<any>();

    const [currentBrandCode, setCurrentBrandCode] = useState<any>(null);

    const [dealerStates, setDealerStates] = useState<any>([]);
    const [dealerCities, setDealerCities] = useState<any>([]);

    /////// REFACTOR
    const { Option } = Select;
    const { addToast } = useToast();
    const { dealershipId, userId } = useAuth();
    const history = useHistory();
    // const [productsList, setProductsList] = useState<any[]>(JSON.parse(localStorage.getItem("@FleetSolutions:cart")!) || []);

    useEffect(() => {
        if (!userId) {
            history.push("/");
        }
        api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`).then((response) => {
            setStates(response.data);
        });
    }, [tabId.id]);

    useEffect(() => {
        api.get(`location/state`).then((response: any) => {
            setDealerStates(response.data);
        });

        const cnpjCompany = localStorage.getItem("@FleetSolutions:cnpjCompany");

        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;
            setDealership(data);
            const allowPFAudi = data.segments && data.segments.indexOf(Segment.audiPF + ",") > -1;
            const allowPJAudi = data.segments && data.segments.indexOf(Segment.audiPJ + ",") > -1;

            if (allowPFAudi || allowPJAudi) {
                setDealershipAudi(true);
            }

            // if (cnpjCompany) {
            //     api.get(`/company/${cnpjCompany.replace(/\D/g, "")}`).then((resCompany) => {
            //         if (resCompany.data) {
            //             api.get(`location/finalPlatePermissions/ByStateCity?state=${resCompany.data.address.state}&city=${normalizeString(resCompany.data.address.city)}`).then((resLicensePlate) => {
            //                 const { data } = resLicensePlate;
            //                 setCanSelectLicensePlate(resLicensePlate ? true : false);
            //             });
            //         }
            //     });
            // }
        });
    }, []);

    function normalizeString(str: string) {
        const specialCharacters: any = {
            Á: "A",
            À: "A",
            Ã: "A",
            Â: "A",
            É: "E",
            È: "E",
            Ê: "E",
            Í: "I",
            Ì: "I",
            Î: "I",
            Ó: "O",
            Ò: "O",
            Õ: "O",
            Ô: "O",
            Ú: "U",
            Ù: "U",
            Û: "U",
            Ç: "C",
        };

        const normalizedStr = Array.from(str)
            .map((char) => specialCharacters[char.toUpperCase()] || char.toUpperCase())
            .join("");

        return normalizedStr;
    }

    useEffect(() => {
        api.get(`location/state`).then((response: any) => {
            setStatesBr(response.data);
        });
        // const url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
        // fetch(url)
        //     .then((res) =>
        //         res.json().then(function (data) {
        //             setStatesBr(
        //                 data.sort((a: any, b: any) => {
        //                     if (a.nome > b.nome) {
        //                         return 1;
        //                     }
        //                     if (a.nome < b.nome) {
        //                         return -1;
        //                     }

        //                     return 0;
        //                 })
        //             );
        //         })
        //     )
        //     .catch((err) => {
        //         console.log(err);
        //     });
    }, []);

    useEffect(() => {
        if (updateProducts) {
            const indexOfLastProduct = currentPage * perPage;
            const indexOfFirstProduct = indexOfLastProduct - perPage;
            const currentProducts = allProducts.slice(indexOfFirstProduct, indexOfLastProduct);

            setTotal(allProducts.length);
            setProductsData(currentProducts);
        }
    }, [currentPage, perPage, allProducts]);

    useEffect(() => {
        api.get(`/segments/by-id-fleet/${segmentId}`).then(({ data }) => {
            const firstActiveSegment = data?.find((item: { status: boolean }) => item?.status === true);

            const deliveryTypesArray = firstActiveSegment.deliveryTypes?.split("|").map(Number) || [];

            const fetchData = async () => {
                const { deliveryOptions } = await fetchDeliveryOptions();

                setDeliveryOptions(
                    deliveryOptions
                        .map((item: { description: any; id: any }) => ({
                            label: item.description,
                            value: item.id,
                        }))
                        .filter((item: { value: any }) => {
                            return deliveryTypesArray.includes(Number(item.value));
                        })
                );
            };

            fetchData();
        });
    }, [segmentId]);

    const fetchDeliveryOptions = async () => {
        try {
            const [deliveryResponse] = await Promise.all([api.get("/enumparameters/DeliveryOptionEnum")]);
            return {
                deliveryOptions: deliveryResponse.data,
            };
        } catch (error) {
            return { deliveryOptions: [] };
        }
    };

    const visibleSegmentDeliveryPlace = () => {
        return true;
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const onChangeOption = (event: any, index: number) => {
        let currentItem = { ...productsList[index] };
        currentItem = {
            ...currentItem,
            pickUpLocationInfos: {
                deliveryOption: event.target.value,
                canChosseLicensePlate: false,
                locationsOptions: [],
                dealershipId: null,
            },
        };
        const updatedProductsList = [...productsList];
        updatedProductsList[index] = currentItem;

        setProductsList(updatedProductsList);
        
    };

    const applyAllSelection = (event: any) => {
        setProductsList((prevProductsList: any) => {
            const updatedProductsList = prevProductsList.map((element: any) => {
                const pickUpLocationInfos = element.pickUpLocationInfos || {};
    
                pickUpLocationInfos.deliveryOption = event;
    
                return {
                    ...element,
                    pickUpLocationInfos: pickUpLocationInfos,
                };
            });
    
            console.log(updatedProductsList);
    
            return updatedProductsList;
        });
    };
    

    const CustomPanel = ({ header, onClear }: any) => {
        const handleClearClick = (e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onClear();
        };
        return (
            <div className="custom-header">
                <span>{header}</span>
                <div className="action">
                    <Button type="text" onClick={handleClearClick} className="clear-button">
                        <img src={ClearIcon} alt="Limpar" />
                        Limpar
                    </Button>
                </div>
            </div>
        );
    };

    const handleAllSelection = (value: any) => {
        setCurrentUf(null);
        setCurrentCity(null);
        setCurrentAddress({
            zipCode: "",
            street: "",
            number: "",
            district: "",
            complement: "",
            city: "",
            state: "",
            canChosseLicensePlate: false,
        });
        setAllOptionsValue(value);
        if (value === 1) {
            api.get(`location/state`).then((response: any) => {
                setDealerStates(response.data);
            });
        }
        setIsModalOpen(value === 5 || value === 3 || value === 1 ? true : false);

    };

    const handleOk = () => {
        setIsModalOpen(false);
        setProductsList((prevProductsList: any) => {
            const updatedProductsList = prevProductsList.map((element: any) => {
                if (allOptionsValue === 5) {
                    return {
                        ...element,
                        pickUpLocationInfos:{
                            uf: currentUf,
                            city: currentCity,
                            deliveryOption: allOptionsValue
                        }
                    };
                } else if (allOptionsValue === 3) {
                    return {
                        ...element,
                        pickUpLocationInfos: {
                            ...(element.pickUpLocationInfos || {}),
                            address: currentAddress,
                            canChosseLicensePlate: currentAddress.canChosseLicensePlate
                        }
                    };
                }
                return element;
            });
            console.log(updatedProductsList);
            return updatedProductsList;
        });
    
        setCurrentAddress({
            zipCode: "",
            street: "",
            number: "",
            district: "",
            complement: "",
            city: "",
            state: "",
            canChosseLicensePlate: false,
        });
    };
    

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleModalForm = () => {
        if (allOptionsValue === 1) {
            return renderDealershipForm(0, true);
        }
        if (allOptionsValue === 3) {
            return renderAddressForm(0, true);
        }
        if (allOptionsValue === 5) {
            return renderUfAndCityForm(0, true);
        }
    };

    

    const handleProductList = () => {
        const handleClear = (index: number) => {
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];

                updatedProductsList[index].pickUpLocationInfos.deliveryOption = null;
                updatedProductsList[index].pickUpLocationInfos.uf = null;
                updatedProductsList[index].pickUpLocationInfos.city = null;
                updatedProductsList[index].pickUpLocationInfos.address = null;
                updatedProductsList[index].pickUpLocationInfos.licensePlate = null;
                updatedProductsList[index].pickUpLocationInfos.canChosseLicensePlate = canSelectLicensePlate;
                updatedProductsList[index].pickUpLocationInfos.willChosseLicensePlate = false;

                updatedProductsList[index].pickUpLocationInfos.city = null;
                updatedProductsList[index].pickUpLocationInfos.locationsOptions = [];
                updatedProductsList[index].pickUpLocationInfos.dealer = null;

                return updatedProductsList;
            });
        };
        return (
            <>
                <div className=" page-head">
                    <div className="row">
                        <div className="">
                            <h1>Entrega do Veículo</h1>
                            <span>Selecione a forma de retirada do(s) modelos(s)</span>
                        </div>
                    </div>
                </div>
                <div className=" all-selection">
                    <div className="row">
                        <div className="">
                            <div className="all-selection-group">
                                <label htmlFor="zipCode">Marcar todos como: </label>
                                <Select placeholder="Selecione uma opção" value={allOptionsValue} style={{ width: 280 }} onChange={(value: any) => handleAllSelection(value)}>
                                    {deliveryOptions?.map((option: any) => (
                                        <Option key={option.value} value={option.value}>
                                            {!visibleSegmentDeliveryPlace() ? option.label : option.label}
                                        </Option>
                                    ))}
                                </Select>
                                <Button className="btn-continue" onClick={() => applyAllSelection(allOptionsValue)}>
                                    Aplicar
                                </Button>
                            </div>
                            <Modal className="modal-ci" width={940} title={`Marcar todos como: ${allOptionsValue === 5 ? "Indicar Estado/Cidade" : "Indicar outro endereço"}`} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                {handleModalForm()}
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="row">
                        <div className="">
                            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
                                {productsList?.length !== 0 &&
                                    productsList?.map((item: any, index: any) => (
                                        <Panel header={<CustomPanel header={item?.productTemplate?.model} onClear={(e: any) => handleClear(index)} />} key={index}>
                                            <div className="car-infos">
                                                <div className="car-image">
                                                    <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`, `${noImageCar}`]} alt="Carro" />
                                                </div>
                                                <div className="infos">
                                                    <div className="items">
                                                        <div className="item">
                                                            <span>Cor:</span>
                                                            {item.productTemplate?.color}
                                                        </div>
                                                        <span className="separator">|</span>
                                                        <div className="item">
                                                            <span>Prazo:</span>
                                                            {item.productTemplate?.deadline} meses
                                                        </div>
                                                        <span className="separator">|</span>

                                                        <div className="item">
                                                            <span>Km Mensal:</span>
                                                            {item.productTemplate?.monthlyKmValue} kms
                                                        </div>
                                                        <span className="separator">|</span>

                                                        <div className="item">
                                                            <span>Quantidade:</span>
                                                            {item?.quantity}
                                                        </div>
                                                    </div>
                                                    <div className="items single">
                                                        <div className="item">
                                                            <span>Opcionais:</span>
                                                            {item?.productTemplate?.optionalText}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="delivery-selection">
                                                <div className="title">Selecione um local para retirada ou entrega:</div>
                                                <div className="options">
                                                    <Radio.Group options={deliveryOptions} onChange={(value) => onChangeOption(value, index)} value={productsList[index]?.pickUpLocationInfos?.deliveryOption} optionType="button" />
                                                </div>
                                                {productsList[index].pickUpLocationInfos?.deliveryOption === 1 && <div className="selected-option">{renderDealershipForm(index)}</div>}
                                                {productsList[index].pickUpLocationInfos?.deliveryOption === 3 && <div className="selected-option">{renderAddressForm(index)}</div>}
                                                {productsList[index].pickUpLocationInfos?.deliveryOption === 5 && <div className="selected-option">{renderUfAndCityForm(index)}</div>}

                                                {productsList[index].pickUpLocationInfos?.canChosseLicensePlate && <div className="license-plate-option">{renderLicensePlate(index)}</div>}
                                            </div>
                                        </Panel>
                                    ))}
                            </Collapse>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderAddressForm = (index: number, allItems: boolean = false) => {
        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;

            if (allItems) {
                setCurrentAddress((prevAddress: any) => ({
                    ...prevAddress,
                    [name]: value || null,
                }));
            } else {
                setProductsList((prevProductsList: any) => {
                    const updatedProductsList = [...prevProductsList];
                    const updatedAddress: Address = { ...updatedProductsList[index].pickUpLocationInfos.address };

                    updatedAddress[name as keyof Address] = value || null;
                    updatedProductsList[index].pickUpLocationInfos.address = updatedAddress;

                    return updatedProductsList;
                });
            }
        };

        const handleZipCodeChange = (value: string) => {
            setCurrentAddress((prevAddress: any) => ({
                ...prevAddress,
                zipCode: value || null,
            }));
        
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];
                updatedProductsList.forEach((item) => {
                    item.pickUpLocationInfos.address.zipCode = value || null;
                });
                return updatedProductsList;
            });
        };

        const handleStateChange = (value: string, index: number) => {
            if (allItems) {
                setCurrentAddress((prevAddress: any) => ({
                    ...prevAddress,
                    state: value || null,
                    city: null,
                }));
            } else {
                setOrderItems((prevOrderItems) => {
                    const updatedOrderItems = [...prevOrderItems];
                    const updatedAddress: Address = { ...updatedOrderItems[index].address };

                    updatedAddress["state"] = value || null;
                    updatedAddress["city"] = null;
                    updatedOrderItems[index].address = updatedAddress;

                    return updatedOrderItems;
                });
            }
            api.get(`location/city?uf=${value}`).then((response: any) => {
                orderItemsCities[index] = response.data;

                setOrderItemsCities((prevOrderItems) => {
                    const updatedOrderItems = [...prevOrderItems];
                    orderItemsCities[index] = response.data;
                    updatedOrderItems[index].canChosseLicensePlate = canSelectLicensePlate;

                    return updatedOrderItems;
                });
            });

            // api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`).then((response) => {
            //     orderItemsCities[index] = response.data;

            //     setOrderItemsCities((prevOrderItems) => {
            //         const updatedOrderItems = [...prevOrderItems];
            //         orderItemsCities[index] = response.data;
            //         updatedOrderItems[index].canChosseLicensePlate = canSelectLicensePlate;

            //         return updatedOrderItems;
            //     });
            // });
        };

        const handleCityChange = (value: string, index: number) => {
            api.get(`location/finalPlatePermissions/ByStateCity?state=${orderItems[index].address?.state}&city=${normalizeString(value)}`).then((resLicensePlate) => {
                const { data } = resLicensePlate;
                if (allItems) {
                    setCurrentAddress((prevAddress: any) => ({
                        ...prevAddress,
                        city: value || null,
                        canChosseLicensePlate: resLicensePlate ? true : false,
                    }));
                } else {
                    setOrderItems((prevOrderItems) => {
                        const updatedOrderItems = [...prevOrderItems];
                        const updatedAddress: Address = { ...updatedOrderItems[index].address };

                        updatedAddress["city"] = value || null;
                        updatedOrderItems[index].address = updatedAddress;
                        updatedOrderItems[index].canChosseLicensePlate = resLicensePlate ? true : false;

                        return updatedOrderItems;
                    });
                }
            });
        };

        const handleKeyUpCep = (e: React.KeyboardEvent<HTMLInputElement>) => {
            let value = (e.target as HTMLInputElement).value;
            value = value.replace(/\D/g, '');

            let previousResponses: any = [];
            if (value.length === 8) {
                api.get(`/ceps?cep=${value}`)
                    .then((response: any) => {
                        if (!response.data.erro) {
                            previousResponses.push(response.data);
                            api.get(`location/finalPlatePermissions/ByStateCity?state=${response.data.uf}&city=${normalizeString(response.data.localidade)}`)
                                .then((resLicensePlate) => {
                                    const { data } = resLicensePlate;
                                    previousResponses.push(data);
                                })
                                .finally(() => {
                                    if (allItems) {
                                        setCurrentAddress((prevAddress: any) => ({
                                            ...prevAddress,
                                            street: previousResponses[0].logradouro,
                                            number: "",
                                            district: previousResponses[0].bairro,
                                            complement: "",
                                            city: previousResponses[0].localidade,
                                            state: statesBr?.find((item: { text: any }) => item.text === previousResponses[0].estado)?.value,
                                            canChosseLicensePlate: previousResponses[1] ? true : false,
                                        }));
                                    } else {
                                        setProductsList((prevProductsList: any) => {
                                            const updatedProductsList = [...prevProductsList];
                                            const updatedAddress: Address = {
                                                ...updatedProductsList[index].pickUpLocationInfos.address,
                                            };

                                            updatedAddress.street = previousResponses[0].logradouro;
                                            updatedAddress.number = "";
                                            updatedAddress.district = previousResponses[0].bairro;
                                            updatedAddress.complement = "";
                                            updatedAddress.city = previousResponses[0].localidade;

                                            updatedAddress.state = statesBr?.find((item: { text: any }) => item.text === previousResponses[0].estado)?.value;
                                            updatedAddress.siglaUF = previousResponses[0].uf;
                                            updatedProductsList[index].pickUpLocationInfos.canChosseLicensePlate = previousResponses[1] ? true : false;

                                            updatedProductsList[index].pickUpLocationInfos.address = updatedAddress;

                                            return updatedProductsList;
                                        });
                                    }
                                });
                        }
                    })
                    .catch((error: any) => {
                        addToast({
                            title: "Campo obrigatório",
                            type: "error",
                            description: "Endereço não encontrado",
                        });
                    });
            }
        };

        return (
            <form className="form">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-3">
                            <label htmlFor="zipCode">CEP</label>
                            <IMaskInput mask="00000-000" unmask={true} name="address.zipCode" value={allItems ? currentAddress["zipCode"] || "" : productsList[index].pickUpLocationInfos?.address?.zipCode || ""} onChange={() => handleZipCodeChange} onKeyUp={handleKeyUpCep} placeholder="Digite o CEP" className="ant-input" />

                            {/* <Input placeholder="Digite o CEP" id="zipcode" name="zipCode" type="text" maxLength={9} value={allItems ? currentAddress["zipCode"] || "" : productsList[index].pickUpLocationInfos?.address?.zipCode || ""} onChange={handleInputChange} onKeyUp={handleKeyUpCep} /> */}
                        </div>
                        <div className="col-6">
                            <label htmlFor="street">Endereço</label>
                            <Input placeholder="Digite o Endereço" id="street" name="street" type="text" maxLength={80} value={allItems ? currentAddress["street"] || "" : productsList[index].pickUpLocationInfos?.address?.street || ""} onChange={handleInputChange} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="zipCode">Número</label>
                            <Input placeholder="Insira o número" id="number" name="number" type="number" maxLength={5} value={allItems ? currentAddress["number"] || "" : productsList[index].pickUpLocationInfos?.address?.number || ""} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label htmlFor="zipCode">Complemento</label>
                            <Input placeholder="Digite o complemento" id="complement" name="complement" type="text" maxLength={40} value={allItems ? currentAddress["complement"] || "" : productsList[index].pickUpLocationInfos?.address?.complement || ""} onChange={handleInputChange} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="zipCode">Bairro</label>
                            <Input placeholder="Digite o nome do Bairro" id="district" name="district" type="text" value={allItems ? currentAddress["district"] || "" : productsList[index].pickUpLocationInfos?.address?.district || ""} onChange={handleInputChange} />
                        </div>

                        <div className="col-3">
                            <label htmlFor="zipCode">Estado</label>
                            <Select value={allItems ? currentAddress["state"] || "" : productsList[index].pickUpLocationInfos?.address?.state || ""} onChange={(value: any) => handleStateChange(value, index)} placeholder="Selecione o Estado">
                                {statesBr.map((option: any) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.text}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="zipCode">Cidade</label>
                            <Select value={allItems ? currentAddress["city"] || "" : productsList[index].pickUpLocationInfos?.address?.city || ""} onChange={(value: any) => handleCityChange(value, index)} placeholder="Selecione a Cidade">
                                {orderItemsCities[index]?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const renderDealershipForm = (index: number, allItems: boolean = false) => {
        const handleStateChange = (value: string) => {
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];

                if (allItems) {
                    updatedProductsList.forEach((item) => {
                        let ufId = statesBr?.find((item: { value: any }) => item.value === value)?.value;
                        item.pickUpLocationInfos.ufId = ufId;
                        item.pickUpLocationInfos.uf = value;
                        item.pickUpLocationInfos.city = null;
                        item.pickUpLocationInfos.dealer = null;
                        item.pickUpLocationInfos.locationsOptions = []; // Limpar a lista de locais ao mudar estado
                    });
                } else {
                    updatedProductsList[index].pickUpLocationInfos.uf = value;
                    let ufId = statesBr?.find((item: { value: any }) => item.value === value)?.value;
                    updatedProductsList[index].pickUpLocationInfos.ufId = ufId;
                    updatedProductsList[index].pickUpLocationInfos.city = null;
                    updatedProductsList[index].pickUpLocationInfos.dealer = null;
                    updatedProductsList[index].pickUpLocationInfos.locationsOptions = []; // Limpar a lista de locais ao mudar estado
                }
                return updatedProductsList;
            });

            setTotalRecords(0);

            if (isModalOpen) {
                api.get(`location/city?uf=${value}`).then((response: any) => {
                    setDealerCities(response.data);
                });
            } else {
                api.get(`location/city?uf=${value}&brandCode=${productsList[index].brandCode}`).then((response: any) => {
                    setDealerCities(response.data);
                });
            }
        };

        const handleCityChange = (value: string) => {
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];
                if (allItems) {
                    updatedProductsList.forEach((item) => {
                        item.pickUpLocationInfos.city = value;
                    });
                } else {
                    updatedProductsList[index].pickUpLocationInfos.city = value;
                }
                return updatedProductsList;
            });

            getDealers(productsList[index].pickUpLocationInfos.uf, value, 1, productsList[index].brandCode);
        };

        const selectDealer = (value: number, item?: any) => {
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];
                if (allItems) {
                    updatedProductsList.forEach((item) => {
                        item.dealer = value;
                        item.dealerInfos = item;
                    });
                } else {
                    updatedProductsList[index].pickUpLocationInfos.dealer = value;
                    updatedProductsList[index].pickUpLocationInfos.dealerInfos = item;
                }
                return updatedProductsList;
            });
            console.log(productsList);
        };

        const paginate = (page: number) => {
            setCurrentPage(page);
            getDealers(orderItems[index].uf, orderItems[index].city!, page);
        };

        const getDealers = (uf: string, city: string, page: number, brandCode?: number) => {
            let requestUrl: string = "";
            if (isModalOpen) {
                requestUrl = `location?UF=${uf}&city=${city}&page=true&quantityPerPage=5&currentPage=${page}`;
            } else {
                requestUrl = `location?UF=${uf}&city=${city}&page=true&quantityPerPage=5&currentPage=${page}&brandCode=${brandCode}`;
            }
            api.get(requestUrl).then(({ data }: any) => {
                if (data.itens[0]) {
                    setProductsList((prevProductsList: any) => {
                        const updatedProductsList = [...prevProductsList];
                        if (allItems) {
                            updatedProductsList.forEach((item) => {
                                item.locationsOptions = data.itens; // Atualizar a lista de locais para todos os itens
                            });
                        } else {
                            updatedProductsList[index].pickUpLocationInfos.locationsOptions = data.itens; // Atualizar a lista de locais para o item específico
                        }
                        return updatedProductsList;
                    });
                    setData(data);
                } else {
                    addToast({
                        title: "Sem dados",
                        type: "error",
                        description: "Não foi encontrado nenhum dado para a pesquisa.",
                    });
                }
            });
        };

        return (
            <form className="form">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="state">Estado</label>
                            <Select value={productsList[index]?.pickUpLocationInfos?.uf || ""} onChange={(value: any) => handleStateChange(value)} placeholder="Selecione o Estado">
                                {dealerStates.map((option: any) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.text}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="city">Cidade</label>
                            <Select value={productsList[index]?.pickUpLocationInfos?.city || ""} onChange={(value: any) => handleCityChange(value)} placeholder="Selecione a Cidade">
                                {dealerCities?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {productsList?.[index]?.pickUpLocationInfos?.locationsOptions?.length > 0 ? <div className="locations-title mt-4 mb-3">Locais disponíveis</div> : null}
                            {productsList?.[index]?.pickUpLocationInfos?.locationsOptions?.map((item: any) => (
                                <div className={productsList?.[index]?.pickUpLocationInfos?.dealer === item.id ? "location-item active" : "location-item"} key={item.id} onClick={() => selectDealer(item.id, item)}>
                                    <div className="name">{item.description}</div>
                                    <div className="address">
                                        {item.address}, {item.number}
                                        <br />
                                        {item.district} - {item.city}, {item.uf} - CEP: {item.zipCode}
                                    </div>
                                </div>
                            ))}
                            <div className="pagination">
                                <PaginationList paginate={(page: number) => paginate(page)} currentPage={currentPage} total={totalRecords} perPage={5} hideOnSinglePage={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const renderUfAndCityForm = (index: number, allItems: boolean = false) => {
        const handleStateChange = (value: string, index: number) => {
            let stateUfInfos = states.find((item) => item.sigla === value)?.nome;
            let ufId = statesBr?.find((item: { text: string }) => item.text === stateUfInfos)?.value;
            if (allItems) {
                setCurrentUf(value);
            } else {
                setProductsList((prevProductsList: any) => {
                    const updatedProductsList = [...prevProductsList];
                    updatedProductsList[index].pickUpLocationInfos.city = null;
                    updatedProductsList[index].pickUpLocationInfos.uf = value;
                    updatedProductsList[index].pickUpLocationInfos.ufId = ufId;
                    return updatedProductsList;
                });

                console.log(productsList);
            }

            api.get(`location/city?uf=${ufId}`).then((response: any) => {
                orderItemsCities[index] = response.data;
                setProductsList((prevProductsList: any) => {
                    const updatedProductsList = [...prevProductsList];
                    if(updatedProductsList[index]?.pickUpLocationInfos?.citiesOptions){
                        updatedProductsList[index].pickUpLocationInfos.citiesOptions = null;

                    }
                    return updatedProductsList;
                });
                console.log(productsList);
            });
        };

        const handleCityChange = (value: string, index: number) => {
            api.get(`location/finalPlatePermissions/ByStateCity?state=${allItems ? currentUf : productsList[index]?.pickUpLocationInfos?.uf}&city=${normalizeString(value[0])}`)
                .then((resLicensePlate) => {
                    const { data } = resLicensePlate;
                    if (allItems) {
                        setCurrentCity(value[0]);

                        let allOrders = productsList;

                        allOrders?.forEach((element: { canChosseLicensePlate: boolean; }) => {
                            element.canChosseLicensePlate = resLicensePlate ? true : false;
                        });

                        setProductsList(allOrders);
                    } else {
                        setProductsList((prevProductsList: any) => {
                            const updatedProductsList = [...prevProductsList];
                            updatedProductsList[index].pickUpLocationInfos.city = value[0];
                            updatedProductsList[index].pickUpLocationInfos.canChosseLicensePlate = resLicensePlate ? true : false;
                            return updatedProductsList;
                        });
                    }
                })
                .catch(() => {
                    if (allItems) {
                        setCurrentCity(value[0]);
                        let allOrders = productsList;

                        allOrders?.forEach((element: { canChosseLicensePlate: boolean; }) => {
                            element.canChosseLicensePlate = false;
                        });

                        setProductsList(allOrders);
                    } else {
                        setProductsList((prevProductsList: any) => {
                            const updatedProductsList = [...prevProductsList];

                            updatedProductsList[index].pickUpLocationInfos.city = value[0];
                            updatedProductsList[index].pickUpLocationInfos.canChosseLicensePlate = false;

                            return updatedProductsList;
                        });
                    }
                });
        };

        return (
            <form className="form">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="zipCode">Estado</label>
                            <Select value={allItems ? currentUf : productsList[index]?.pickUpLocationInfos.uf || ""} onChange={(value: any) => handleStateChange(value, index)} placeholder="Selecione o Estado">
                                {states.map((option: any) => (
                                    <Option key={option.sigla} value={option.sigla}>
                                        {option.sigla}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="zipCode">Cidade</label>
                            <Select value={allItems ? currentCity : productsList[index]?.pickUpLocationInfos.city || ""} onChange={(value: any) => handleCityChange(value, index)} placeholder="Selecione a Cidade">
                                {orderItemsCities[index]?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const renderLicensePlate = (index: number) => {
        const handleStateChange = (value: string, index: number) => {
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];

                updatedProductsList[index].pickUpLocationInfos.licensePlate = value;

                return updatedProductsList;
            });
        };

        const onChange = (checked: boolean, index: number) => {
            setProductsList((prevProductsList: any) => {
                const updatedProductsList = [...prevProductsList];

                updatedProductsList[index].pickUpLocationInfos.willChosseLicensePlate = checked;

                return updatedProductsList;
            });
        };

        const licensePlateOptions = () => {
            return ["1 ou 2", "3 ou 4", "5 ou 6", "7 ou 8", "9 ou 0"];
        };
        return (
            <form>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                Escolher Final de Placa
                                <span>Você selecionou uma localização em que permite escolher o final da placa. Deseja escolher?</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <Switch checked={productsList[index].pickUpLocationInfos?.willChosseLicensePlate} onChange={(value) => onChange(value, index)} />
                        </div>
                        <div className="col-12 d-flex align-items-center gap-row">
                            {productsList[index].pickUpLocationInfos.willChosseLicensePlate && (
                                <>
                                    <label htmlFor="zipCode">Escolha no mínimo duas opções:</label>
                                    <Select onChange={(value: any) => handleStateChange(value, index)} placeholder="Selecione os finais de placa" mode="multiple">
                                        {licensePlateOptions().map((option: any) => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </div>
                        {productsList[index].pickUpLocationInfos.willChosseLicensePlate && (
                            <div className="col-12">
                                <div className="obs d-flex justify-content-end mt-2">*Sujeito à disponibilidade de estoque, caso não haja o final de placa escolhido, entraremos em contato.</div>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        );
    };

    return <Container>{handleProductList()}</Container>;
};

export default PickupLocationPJ;
