import React from 'react'
import { findPersonType } from 'utils/helpers'
import { Container } from './styles'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'

interface Props {
  segmentName: string
  customerType: string
  idFleet: number
  idIbratan: string
}

const SelectSegmentButton = ({ segmentName, customerType, idFleet, idIbratan }: Props) => {
  const personType = findPersonType(customerType)
  const history = useHistory()

  const handleSaveToLocalStorage = () => {
    // if(customerType?.toLowerCase() === 'pj'){
    //   Modal.confirm({
    //     title: 'Novo fluxo para Empresas',
    //     icon:false,
    //     content: 'Uma nova jornada para pedidos para Empresas está disponível. Gostaria de conhecer?',
    //     okText: 'Conhecer',
    //     cancelText: 'Seguir no fluxo atual',
    //     className:'new-flow-modal',
    //     onOk: () => {
    //       localStorage.setItem(
    //         '@FleetSolutions:selectedSegment',
    //         JSON.stringify({ segmentName, customerType, idFleet, idIbratan })
    //       )
    //       // Redireciona apenas após a confirmação do modal
    //       history.push(`/ofertas/${personType?.type.toLowerCase()}/${idFleet}`)
    //     },
    //     onCancel: () => {
    //       localStorage.setItem(
    //         '@FleetSolutions:selectedSegment',
    //         JSON.stringify({ segmentName, customerType, idFleet, idIbratan })
    //       )
    //       history.push(`/offers/${personType?.type.toLowerCase()}/${idFleet}`)
  
    //       // Opcional: mantenha o fluxo sem redirecionamento
    //     },
    //   })
    // }
    // else{
    //   localStorage.setItem(
    //     '@FleetSolutions:selectedSegment',
    //     JSON.stringify({ segmentName, customerType, idFleet, idIbratan })
    //   )
    //   history.push(`/offers/${personType?.type.toLowerCase()}/${idFleet}`)
    // }
    localStorage.setItem(
      '@FleetSolutions:selectedSegment',
      JSON.stringify({ segmentName, customerType, idFleet, idIbratan })
    )
    history.push(`/offers/${personType?.type.toLowerCase()}/${idFleet}`)
  }

  return (
    <Container onClick={handleSaveToLocalStorage}>
      <img src={personType?.icon} alt={customerType} />

      <span>
        {personType?.label} - {segmentName}
      </span>
    </Container>
  )
}

export default SelectSegmentButton
