import axios, { AxiosResponse } from 'axios';
import { message } from 'antd';

import { getToken } from '../hooks/auth';

export const defaultBackendUrl =
  // 'https://backend.vwsignanddrive.com.br/api' || 'https://backend.vwsignanddrive.com.br/api';
  'https://backend.vwsignanddrive.com.br/api' || 'http://a13b9f33ff9bb4b82a41c27b260ae60b-802566193.sa-east-1.elb.amazonaws.com/api';

const api = axios.create({
  baseURL: defaultBackendUrl
});

let lastTokenCheckTime: number | null = null;

const shouldCheckToken = () => {
  if (!getToken()) return false; // Se não há token, não fazer verificação
  if (!lastTokenCheckTime) return true; // Se nunca foi verificado antes, verificar agora
  const twentyMinutesInMilliseconds = 5 * 60 * 1000;
  const currentTime = new Date().getTime();
  return currentTime - lastTokenCheckTime > twentyMinutesInMilliseconds;
};

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (shouldCheckToken()) {
    const token = getToken();
    try {
      // Fazer chamada para o endpoint users/RefreshToken
      const response: AxiosResponse<any> = await axios.post(`${defaultBackendUrl}/users/RefreshToken`,null,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        // Token válido, atualizar o tempo da última verificação
        lastTokenCheckTime = new Date().getTime();
        localStorage.setItem('@FleetSolutions:token', response.data.token)
      }
    } catch (error) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.replace(window.location.origin + '/?session=false');
      // location.reload()
      // Tratar erro, se necessário
    }
  }

  return config;
});

api.interceptors.response.use(
  async (res) => {
    const { status } = res;

    if (
      status === 200 ||
      status === 401 ||
      status === 201 ||
      status === 404 ||
      status === 204
    ) {
      return res;
    } else {
      message.error(
        'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde'
      );
    }
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
