import styled from 'styled-components'

export const ResumeContent = styled.div`
   * {
        font-family: "DM Sans", sans-serif;
    }
    background-color:#E1EAEC;
    padding:32px;
    border-radius: 16px;
    .resume{
        display: flex;
        align-items: center;
        gap: 16px;
        .values{
            .products{
                font-size: 22px;
                color: #3C505A;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 8px;
            }
            .contract{
                display: flex;
                gap: 16px;
                .item{
                        color:#4A4A4A;
                        font-size:18px;
                        font-weight:700;
                        display:flex;
                        gap:6px;
                        align-items:center;
                        &:not(:first-child){
                            color: #4A4A4A;
                            font-size: 18px;
                            font-weight: 700;
                            border-left: 1px solid #C2C2C2;
                            padding-left: 16px;
                        }
                    span{
                        font-size:14px;
                        font-weight:400;
                    }
                }
            }
        }
    }
`
