import { Modal } from "antd";
import styled from "styled-components";

const buttonColors = {
  first: `
  background-color: #0161af;
  color: #fff;
`,
  second: `
  background-color: #fff;
  color:  #0161af;
`,
};

const disabledButton = `
  opacity: 0.5;
  cursor: auto;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 15px 15px 0 0;
  }

  .ant-modal-title {
    font-size: 18px;
  }
`;

export const StyleText = styled.p`
  font-size: 14px;
  color: #4a4a4a;
  text-align: center;
`;

export const StyledFooter = styled.div`
  display: flex;
  gap: 15px;
  justify-content: end;
  padding: 5px 0;
`;

export const StyledButton = styled.button<{
  color?: keyof typeof buttonColors;
  disabled?: boolean;
}>`
  padding: 4px 16px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  height: 32px;
  border: 2px solid #0161af;
  border-radius: 30px;
  ${(props) => props.disabled && disabledButton}
  ${(props) => (props.color ? buttonColors[props.color] : buttonColors.first)}
`;

export const StyledIcon = styled.span`
  display: inline-block;
  margin-left: 4px;
`;
