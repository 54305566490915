import { ColorI } from "./types";

export const DEFAULT_GRADIENT = {
  start: {
    rgb: [204, 9, 9],
    percentage: 0.3,
  },
  end: {
    rgb: [255, 195, 0],
    percentage: 0.8,
  },
};

export function getColorForPosition(
  position: number,
  maxPosition: number,
  startColor: number[],
  endColor: number[]
) {
  const ratio = position / (maxPosition - 1);
  const color = startColor.map((start, i) =>
    Math.round(start + ratio * (endColor[i] - start))
  );
  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

export function generateLinearGradient(start: ColorI, end: ColorI) {
  const startColor = start.rgb.join(", ");
  const endColor = end.rgb.join(", ");
  const startPercent = start.percentage * 100;
  const endPercent = end.percentage * 100;
  return `linear-gradient(90deg, rgb(${startColor}) ${startPercent}%, rgb(${endColor}) ${endPercent}%)`;
}
