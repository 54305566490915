import React, { useEffect, useState } from "react";
import LevelIndicator from "../LevelIndicator";
import {
  StyledButton,
  StyledFooter,
  StyledIcon,
  StyledModal,
  StyleText,
} from "./styles";
import ArrowRightIcon from "assets/icons/arrow-right.svg";
import api from "services/api";
import { AxiosResponse } from "axios";

interface HEADLIGHTRESPONSEI {
  portalNumber: number;
}

type Props = {
  orderId: number | undefined;
  onCancel: () => void;
  onOk: () => void;
};

const SCALE = [1, 2, 3, 4, 5, 6];
const NON_REANALYZABLE_MSG = "Não passível de reanálise";
const REANALYZABLE_MSG = "Passível de reanálise";
const DESCRIPTIONS = [
  NON_REANALYZABLE_MSG,
  NON_REANALYZABLE_MSG,
  NON_REANALYZABLE_MSG,
  REANALYZABLE_MSG,
  REANALYZABLE_MSG,
  REANALYZABLE_MSG,
];

function ReanalysisModal({ orderId, onCancel, onOk }: Props) {
  const [portalNumber, setPortalNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHeadlight = async () => {
      setLoading(true);
      try {
        const response: AxiosResponse<HEADLIGHTRESPONSEI> = await api.get(
          `/creditanalyst/headlight/${orderId}`
        );
        setPortalNumber(response.data.portalNumber);
      } finally {
        setLoading(false);
      }
    };
    if (orderId) {
      fetchHeadlight();
    }
  }, [orderId]);

  return (
    <StyledModal
      visible={!loading}
      title="Solicitar Reanálise"
      footer={
        <StyledFooter>
          <StyledButton color="second" onClick={onCancel}>
            Não, cancelar
          </StyledButton>
          <StyledButton disabled={loading} onClick={onOk}>
            Sim, solicitar reanálise{" "}
            <StyledIcon>
              <img src={ArrowRightIcon} />
            </StyledIcon>
          </StyledButton>
        </StyledFooter>
      }
      onCancel={onCancel}
    >
      <>
        {portalNumber > 0 && (
          <>
            <StyleText>
              Atenção, você está solicitando a reanálise, confira abaixo o
              indicador de chances:
            </StyleText>
            <LevelIndicator
              level={portalNumber}
              scale={SCALE}
              descriptions={DESCRIPTIONS}
            />
          </>
        )}
        <StyleText>Tem certeza que ainda assim deseja solicitar?</StyleText>
      </>
    </StyledModal>
  );
}

export default ReanalysisModal;
