import React, { ChangeEvent, useEffect, useState } from "react";
import { CompanyInfosBox, ImportantInfos, Observation, PageHead, PricesResume, ProductsList, ResumeContainer, WrapperImage } from "./styles";
import carDetails from "../../../../../../assets/icons/car-details.svg";
import formatValue from "utils/formatValue";
import { Checkbox, Collapse } from "antd";
import ImgWithFallback from "components/ImgWithFallback";
import noImageCar from "assets/defaultImages/no-image.png";
import api, { defaultBackendUrl } from "services/api";
import TextArea from "antd/lib/input/TextArea";

const { Panel } = Collapse;

const OrderResumePJ = ({ onObsChange, currentTab }: any) => {
    const [totalVehicleValue, setTotalVehicleValue] = useState<number>();
    const [totalMonthlyValue, setTotalMonthlyValue] = useState<number>();
    const [totalProducts, setTotalProducts] = useState<number>();
    const [totalModels, setTotalModels] = useState<number>();
    const [maxDeadline, setMaxDeadline] = useState<number>();
    const [productsList, setProductsList] = useState<any[]>(JSON.parse(localStorage.getItem("@FleetSolutions:cart")!) || []);
    const [segment, setSegment] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:currentSelectedSegment")!) || null);
    const [companyInfos, setCompanyInfos] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!) || null);
    const [showObs, setShowObs] = useState<boolean>(false);
    const [obsValue, setObsValue] = useState("");

    useEffect(() => {
        const fetchCompanyData = async () => {
            if (companyInfos) {
                setCompanyInfos(null);
                const storedInfos = JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!);
                setCompanyInfos(storedInfos);
                try {
                    const companyResponse = await api.get(`/company/${storedInfos?.cnpj}`);
                    setCompanyInfos({
                        ...storedInfos,
                        ...companyResponse.data,
                    });
                } catch (error) {
                    console.error("Erro ao buscar dados da empresa:", error);
                }
            }
        };
        if (currentTab === "5") {
            fetchCompanyData();
        }
    }, [currentTab]);

    useEffect(() => {
        const handleResumeInfos = () => {
            setTotalVehicleValue(
                productsList.reduce((total: number, item: { productTemplate: { monthlyInstallment: number; deadline: number }; quantity: number }) => {
                    return total + item.productTemplate?.monthlyInstallment * item.productTemplate?.deadline * item.quantity;
                }, 0)
            );
            setTotalMonthlyValue(
                productsList.reduce((total: number, item: { productTemplate: { monthlyInstallment: number }; quantity: number }) => {
                    return total + (item.productTemplate?.monthlyInstallment * item.quantity || 0);
                }, 0)
            );
            setTotalProducts(
                productsList.reduce((total: any, item: { quantity: any }) => {
                    return total + (item.quantity || 0);
                }, 0)
            );
            const uniqueModels = new Set(productsList.map((item: { productTemplate: { modelCode: any } }) => item.productTemplate?.modelCode));
            setTotalModels(uniqueModels.size);
            setMaxDeadline(Math.max(...productsList.map((item: { productTemplate: { deadline: any } }) => item.productTemplate?.deadline || 0)));
        };
        handleResumeInfos();
    }, [productsList]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setObsValue(value);
        onObsChange(value);
    };

    const getDeliveryTypeDescription = (type: number) => {
        switch (type) {
            case 1:
                return "Concessionária/Parceiro";
            case 2:
                return "Endereço cliente";
            case 3:
                return "Outro Endereço";
            case 4:
                return "DN do pedido";
            case 5:
                return "Estado/Cidade";
            default:
                return "";
        }
    };

    return (
        <ResumeContainer>
            <PageHead>
                <h1>Resumo do Pedido</h1>
            </PageHead>
            <CompanyInfosBox>
                <div className="title">Informações da Empresa</div>
                <div className="infos-row">
                    <div className="infos-group">
                        <div className="infos-title">Informações Gerais</div>
                        <div className="item">
                            <span>CNPJ:</span>
                            {companyInfos?.cnpj}
                        </div>
                        <div className="item">
                            <span>Razão Social:</span>
                            {companyInfos?.companyName}
                        </div>
                    </div>
                    <div className="infos-group">
                        <div className="infos-title">Contato da Empresa</div>
                        <div className="item">
                            <span>Nome Completo:</span>
                            {companyInfos?.contactName}
                        </div>
                        <div className="item">
                            <span>CPF:</span>
                            {companyInfos?.cpf}
                        </div>
                        <div className="item">
                            <span>Celular:</span>
                            {companyInfos?.phone}
                        </div>
                        <div className="item">
                            <span>E-mail:</span>
                            {companyInfos?.mail}
                        </div>
                    </div>
                    <div className="infos-group">
                        <div className="infos-title">Contato Financeiro</div>
                        <div className="item">
                            <span>Nome Completo:</span>
                            {companyInfos?.financialContactName}
                        </div>
                        <div className="item">
                            <span>E-mail:</span>
                            {companyInfos?.financialContactEmail}
                        </div>
                    </div>
                </div>
                <div className="contacts-row">
                    <div className="title">Signatários</div>
                    <div className="contacts">
                        {companyInfos?.contacts?.map((contact: any, index: any) => (
                            <div className="contact" key={contact.contactId || index}>
                                <div className="title">Signatário {index + 1}</div>
                                <div className="item">
                                    <span>Nome Completo:</span>
                                    {contact.name}
                                </div>
                                <div className="item">
                                    <span>CPF:</span>
                                    {contact.cpf}
                                </div>
                                <div className="item">
                                    <span>E-mail:</span>
                                    {contact.email}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </CompanyInfosBox>
            <PricesResume>
                <div className="resume-title">Resumo do Pedido</div>
                <div className="resume">
                    <div className="icon">
                        <img src={carDetails} alt="Resumo do pedido" />
                    </div>
                    <div className="values">
                        <div className="products">
                            {totalModels} Modelo(s) / {totalProducts} veículo(s)
                        </div>
                        <div className="contract">
                            <div className="item">
                                <span>Maior prazo:</span>
                                {maxDeadline} meses
                            </div>
                            <div className="item">
                                <span>Valor total de contrato:</span>
                                {formatValue(totalVehicleValue || 0)}
                            </div>
                            <div className="item">
                                <span>Parcela Mensal:</span>
                                {formatValue(totalMonthlyValue || 0)}
                            </div>
                        </div>
                    </div>
                </div>
            </PricesResume>
            <ProductsList>
                <div className="products-title">Informações do(s) Veículo(s)</div>
                {productsList.length > 0 && (
                    <Collapse>
                        {productsList?.map((product: any, index: number) => (
                            <Panel header={product?.model} key={index}>
                                <div className="offer-content">
                                    <div className="configs">
                                        <div className="image">
                                            <WrapperImage>
                                                <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product?.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${product?.modelCode}.png`, noImageCar]} alt={product?.model} />
                                            </WrapperImage>
                                        </div>
                                        <div className="offer-template">
                                            <div className="item">
                                                <span>Cor:</span>
                                                {product?.productTemplate?.color}
                                            </div>
                                            <span className="separator">|</span>
                                            <div className="item">
                                                <span>Prazo:</span>
                                                {product?.productTemplate?.deadline}
                                            </div>
                                            <span className="separator">|</span>
                                            <div className="item">
                                                <span>KM Mensal:</span>
                                                {product?.productTemplate?.monthlyKmValue}
                                            </div>
                                            <span className="separator">|</span>
                                            <div className="item">
                                                <span>Quantidade:</span>
                                                {product?.quantity}
                                            </div>
                                            {product?.pickUpLocationInfos?.licensePlate && (
                                                <>
                                                    <span className="separator">|</span>
                                                    <div className="item">
                                                        <span>Final de Placa:</span>
                                                        {product?.pickUpLocationInfos?.licensePlate?.join(", ")}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="prices">
                                        <div className="price-item">
                                            <div className="unity-price">
                                                <label>Valor Unitário: </label>
                                                {formatValue(product?.productTemplate?.monthlyInstallment)}
                                                <span> / por mês</span>
                                            </div>
                                        </div>
                                        <div className="price-item last">
                                            <div className="total-price">
                                                <label>Total: </label>
                                                {formatValue(product?.productTemplate?.monthlyInstallment * product?.quantity)}
                                                <span> / por mês</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="detail-title">Detalhes da assinatura</div>

                                        <div className="offer-infos">
                                            <div className="offer-items">
                                                <div className="title">Opcionais:</div>
                                                <p>{product?.productTemplate?.optionalText || "-"}</p>
                                            </div>
                                            <div className="offer-items">
                                                <div className="title">Acessórios:</div>
                                                <p>{product?.productTemplate?.accessoryText?.toLowerCase() || "-"}</p>
                                            </div>
                                        </div>
                                        <div className="contract-infos">
                                            <div className="item">
                                                <div className="title">Serviços Inclusos:</div>
                                                Manutenção Preventiva
                                            </div>
                                            <div className="item">Assistência 24 horas</div>
                                            <div className="item">Gestão de multas</div>
                                            <div className="item">Documentação</div>
                                            <div className="item">Auto Proteção</div>
                                        </div>
                                        <div className="contract-infos">
                                            <div className="item">
                                                <div className="title">Custo/ KM Excedente:</div>
                                                {formatValue(product?.productTemplate?.overrunKm)}
                                            </div>
                                            <div className="item">
                                                <div className="title">Coparticipação em sinistro:</div>
                                                5% da tabela FIPE
                                            </div>
                                            <div className="item">
                                                <div className="title">Prazo de entrega:</div>
                                                {product?.productTemplate?.deadlineInfo}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="detail-title">Entrega do Veículo</div>
                                        <div className="delivery">
                                            <span>Forma de Retirada:</span>
                                            {getDeliveryTypeDescription(product?.pickUpLocationInfos?.deliveryOption)}
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        ))}
                    </Collapse>
                )}
            </ProductsList>
            <ImportantInfos dangerouslySetInnerHTML={{ __html: segment?.orderSummary }} />
            <Observation>
                <Checkbox checked={showObs} onChange={(e) => setShowObs(e.target.checked)}>
                    <div className="check-label">
                        Deixar observação
                        <span>Marque caso queira deixar observações a respeito da validação e cadastro deste pedido.</span>
                    </div>
                </Checkbox>
                {showObs && (
                    <div className="obs-field">
                        <span>Observação</span>
                        <TextArea onChange={handleChange} placeholder="Digite a sua observação" rows={4} />
                    </div>
                )}
            </Observation>
        </ResumeContainer>
    );
};

export default OrderResumePJ;
