import styled from 'styled-components'

export const WrapperDoc = styled.div`
  /* background: #ffffff;
  border: 1px solid #005faf;
  border-radius: 36px;
  width: 80%;
  max-width: 787px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin: 12px; */

  .left {
    p {
      margin: 0;
      color: #005faf;
      font-size: 14px;
      font-weight: bold;
    }

    .name-file {
      font-size: 18px;
      font-weight: bold;

      span {
        font-weight: 500;
      }
    }
  }

  .more-info {
    text-decoration: underline;
    font-size: 14px;
    color: #000;
    cursor: pointer;
  }

  .right {
    .upload {
      flex-direction: column;

      .ant-tooltip-inner {
        display: none;
      }

      span.anticon.anticon-delete {
        display: block;
      }
    }

    .btn-upload {
      width: 192px;
      border: 3px solid #005faf;
      background: #fff;
      color: #005faf;
      font-size: 11px;
      margin-left: 12px;
      align-items: center;
      display: flex;
      justify-content: space-between;

      svg {
        color: #005faf;
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    width: auto;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-upload.ant-upload-drag{
    padding: 32px 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323232;
    font-weight: 700;
    gap: 16px;
    border-radius: 8px;
    border-color: #CCCCCC;
    .ant-upload-drag-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        &:hover{
            border-color:#0161af;
        }
    }


  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-upload-list{
      display:none;
    }
`
export const FileBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 2px solid #0161AF;
    border-radius: 8px;
    padding: 32px 0;
    gap: 10px;
    .btn-back{
      border: 2px solid #0161AF;
      color:#0161AF;
      font-size:14px;
      background-color:#fff;
    }
    .doc-name{
      color:#323232;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
    }
    .doc-file{
      color:#0161AF;
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      cursor: pointer;
    }
`