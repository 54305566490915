import React, { useState, useCallback, useEffect } from "react";
import * as S from "./styles";
import formatValue from "utils/formatValue";
import carDetails from "../../../../../assets/icons/car-details.svg";


const OrderResume: React.FC<any> = ({productsList}) => {
    const [totalVehicleValue, setTotalVehicleValue] = useState<number>();
    const [totalMonthlyValue, setTotalMonthlyValue] = useState<number>();
    const [totalProducts, setTotalProducts] = useState<number>();
    const [totalModels, setTotalModels] = useState<number>();
    const [maxDeadline, setMaxDeadline] = useState<number>();

    useEffect(() => {
        const handleResumeInfos = () => {
            setTotalVehicleValue(productsList.reduce((total: number, item: { productTemplate: { monthlyInstallment: number; deadline:number }; quantity: number; }) => {
                return total + ((item.productTemplate?.monthlyInstallment * item.productTemplate?.deadline) * item.quantity);
            }, 0));
            setTotalMonthlyValue(productsList.reduce((total: number, item: { productTemplate: { monthlyInstallment: number; }; quantity: number; }) => {
                return total + (item.productTemplate?.monthlyInstallment * item.quantity || 0);
            }, 0));
            setTotalProducts(productsList.reduce((total: any, item: { quantity: any; }) => {
                return total + (item.quantity || 0);
            }, 0));
            const uniqueModels = new Set(productsList.map((item: { productTemplate: { modelCode: any; }; }) => item.productTemplate?.modelCode));
            setTotalModels(uniqueModels.size);
            setMaxDeadline(Math.max(...productsList.map((item: { productTemplate: { deadline: any; }; }) => item.productTemplate?.deadline || 0)));
        }
        handleResumeInfos();
    }, [productsList]);

    return (
        <S.ResumeContent>
            <div className="resume">
                <div className="icon">
                <img src={carDetails} alt="Resumo do pedido" />
                </div>
                <div className="values">
                    <div className="products">
                        {totalModels} Modelo(s) / {totalProducts} veículo(s)
                    </div>
                    <div className="contract">
                        <div className="item">
                            <span>Maior prazo:</span>
                            {maxDeadline} meses
                        </div>
                        <div className="item">
                            <span>Valor total de contrato:</span>
                            {formatValue(totalVehicleValue || 0)}
                        </div>
                        <div className="item">
                            <span>Parcela Mensal:</span>
                            {formatValue(totalMonthlyValue || 0)}
                        </div>
                    </div>
                </div>
            </div>
        </S.ResumeContent>
    );
};

export default OrderResume;
