import styled from 'styled-components'

export const CompanyForm = styled.div`
   * {
        font-family: "DM Sans", sans-serif;
    }
    .fields-group{
        display: flex;
        flex-direction: column;
        margin-bottom:0;
        .ant-form-item-explain-error{
            font-size: 11px;
            text-align:right;
        }
        .ant-form-item-label{
            padding-bottom:4px;
            color:#4A4A4A;
            font-size:14px;
        }
        .title{
            font-size: 16px;
            font-weight: 700;
            color: #4A4A4A;
            border-bottom: 1px solid #C2C2C2;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
        .fields{
            .fields-row{
                display: flex;
                gap: 16px;
                align-items: center;
                
                .ant-form-item{
                    flex:1;
                    .ant-input{
                        border-radius:23px;
                    }
                    .ant-select-selector{
                        border: 1px solid #d9d9d9 !important;
                        border-radius:23px;
                    }
                }
            }
        }
    }
    .add-contact, .remove-contact{
        border: 2px solid #0161AF;
        background: none;
        width: 100%;
        border-radius: 40px;
        margin-bottom: 40px;
        font-size: 14px;
        font-weight: 700;
        color: #0161AF;
        padding: 10px;
        height: auto;
        line-height:14px;
        box-shadow: none;
        text-shadow: none;
    }
    .remove-contact{
        width: auto;
        margin-bottom: 0;
        padding:8px 12px;
        &[disabled]{
            color:#C2C2C2;
            border-color:#C2C2C2;
        }
    }

    .switch-group{
        display: flex;
        flex: 0.7 !important;
        .ant-switch-checked {
            background-color: #0e61b0;
        }
        .ant-form-item-label{
            span{
                padding-bottom: 4px;
                color: #4A4A4A;
                font-size: 14px;
                font-weight:700;
            }
        }
        .ant-form-item-control-input-content{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 8px;
            color: #4A4A4A;
            font-size: 14px;
        }
    }
`