import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const withLoader = (Component: React.ElementType) => {
    return ({ isLoading, ...props }: any) => {
        const antIcon = <LoadingOutlined style={{ fontSize: 14, color: "#BFBFBF" }} spin />;
        return (
            <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                <Component {...props} />
                {isLoading && (
                    <div
                        style={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Spin indicator={antIcon} />
                    </div>
                )}
            </div>
        );
    };
};

export default withLoader;
