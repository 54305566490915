import styled from 'styled-components'

export const CompanyForm = styled.div`
   * {
        font-family: "DM Sans", sans-serif;
    }
    .fields-group{
        display: flex;
        flex-direction: column;
        margin-bottom:0;
        .ant-form-item-explain-error{
            font-size: 11px;
            text-align:right;
        }
        .ant-form-item-label{
            padding-bottom:4px;
            color:#4A4A4A;
            font-size:14px;
        }
        .title{
            font-size: 18px;
            font-weight: 700;
            color: #3C505A;
            border-bottom: 1px solid #C2C2C2;
            padding-bottom: 16px;
            margin-bottom: 16px;
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
            }
        }
        .fields{
            padding: 0 5%;
            .fields-row{
                display: flex;
                gap: 16px;
                .ant-form-item{
                    flex:1;
                    .ant-input{
                        border-radius:23px;
                    }
                }
            }
        }
    }

`