/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { Content, SelectSegmentButtons } from './styles'
import SelectSegmentButton from '../SelectSegmentButton'
import api from 'services/api'
import { ISegment, IListSegmentsData } from 'utils/types'

interface Props {
  visible: boolean
  onCancel: () => void
}

const OrderModal = ({ visible, onCancel }: Props) => {
  const [dealershipSegments, setDealershipSegments] = useState<ISegment[]>([])

  const dealershipID = localStorage.getItem('@FleetSolutions:dealershipId')

  const loadDealershipSegments = () => {
    api.get(`/dealerships/${dealershipID}`).then(({ data }) => {
      const format = (segment: IListSegmentsData) => ({
        iD_Fleet: segment.idSegment,
        iD_Ibratan: segment.idIbratan,
        name: segment.name,
        type: segment.type
      })

      setDealershipSegments(data.listSegments.map(format))
    })
  }

  useEffect(() => {
    localStorage.removeItem('@FleetSolutions:selectedSegment')
    localStorage.removeItem('@FleetSolutions:isSDBlindado')
    localStorage.removeItem("@FleetSolutions:cart")
    loadDealershipSegments()
  }, [])

  return (
    <Modal visible={visible} onCancel={onCancel} footer="">
      <Content>
        <h3>Realizar novo pedido</h3>

        <p>Escolha o tipo de cliente abaixo</p>

        <SelectSegmentButtons>
          {dealershipSegments.map((segment: ISegment, i: number) => (
            <SelectSegmentButton
              key={i}
              segmentName={segment.name}
              customerType={segment.type}
              idFleet={segment.iD_Fleet}
              idIbratan={segment.iD_Ibratan}
            />
          ))}
        </SelectSegmentButtons>
      </Content>
    </Modal>
  )
}

export default OrderModal
