/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Button from '../../components/Button'
import { IState } from '../../store'
import { IFilterOffer } from '../../store/modules/offers/types'
import { useAuth } from '../../hooks/auth'

import { Container, Content, WrapperCards, Cards, Cart } from './styles'
import { setClientType } from '../../store/modules/clientType/actions'
import CardOffer from 'components/CardOffer'
import { services } from 'services'
import api from 'services/api'
import Loading from 'components/Loading'
import { useToast } from 'hooks/toast'

interface IParams {
  type: string
  segmentId: string
}

export type iPropsProducts = {
  id: number
  brandCode?: number
  productId: number
  quantity?: number
  color: string
  modelCode: number
  model: string
  deadlines?: {
    deadline: number
  }[]
  monthlyKmValues?: {
    monthlyKmValue: number
  }[]
  kickback?: {
    kickback: number
  }[]
  productWithColor?: {
    color: string
    productId: number
  }[]
  monthlyInstallment?: string
  optional?: string
  hullFranchiseValue?: string
  overrunKm?: number
  offerOptional?: {
    optional: string
  }[]
  amountAvailable?: number
  isBlindado?:boolean;
}

const Offers: React.FC = () => {
  const { userId } = useAuth()
  const history = useHistory()
  const { addToast } = useToast()

  const filter = useSelector<IState, IFilterOffer>((state) => state.offers)
  const { type, segmentId } = useParams<IParams>()
  const dispatch = useDispatch()

  const [products, setProducts] = useState<iPropsProducts[]>([] as any)
  //  const [openDrawer, setOpenDrawer] = useState(true)
  const [offers, setOffers] = useState([])
  // const [filterModelOption, setFilterModelOption] = useState([])
  const [page, setPage] = useState(1)
  // const [maxValueOffer, setMaxValueOffer] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [isLoadingOffers, setIsLoadingOffers] = useState(false)

  const quantityTotal = products.reduce(
    (total, item: any) => total + item.quantity,
    0
  )

  localStorage.setItem('@FleetSolutions:segmentId', segmentId)

  // const marks = {
  //   100: {
  //     style: {
  //       color: '#0C5FAF'
  //     },
  //     label: formatValue(maxValueOffer)
  //   }
  // }

  useEffect(() => {
    if (!userId) {
      history.push('/')
    }

    localStorage.removeItem(
      '@FleetSolutions:isSDBlindado'
    )

    const { modelCode, color } = filter

    dispatch(setClientType(type))

    const dealershipId = localStorage.getItem('@FleetSolutions:dealershipId')
    let isExists = false
    api.get(`dealerships/${dealershipId}`).then((response:any) => {
      response.data.listSegments.map((it: any) => {
        if (it.idSegment.toString() === segmentId) isExists = true
      })
      if (!isExists) {
        addToast({
          title: 'permissão negada!',
          type: 'error',
          description: 'acesso retrito!'
        })
        history.push('/my-orders')
      }
    })

    localStorage.setItem('@FleetSolutions:clientType', type)
    if (modelCode !== 0 && color !== '') {
      setIsLoadingOffers(true)
      const segmentIdNumber = Number(segmentId);
    
      const requestOffers = (salesChannel:any) =>
        services.postOffers({
          modelCode: [modelCode],
          brandCode: [],
          colors: [color],
          minMonthlyInstallment: 0,
          maxMonthlyInstallment: 0,
          page: true,
          quantityPerPage: 10,
          currentPage: page,
          segmentId: segmentIdNumber,
          salesChannel: salesChannel,
        });
    
      const handleResponse = (response:any) => {
        setOffers(response.data.itens);
        setTotalAmount(response.data.totalAmount);
      };
    
      if (segmentIdNumber === 9) {
        Promise.all([
          requestOffers('S&D'),
          requestOffers('S&D - Blindado'),
        ])
          .then(([response1, response2]) => {
            const blindadoItems = response2.data.itens.map((item: any) => ({
              ...item,
              isBlindado: true,
            }));

            const combinedOffers:any = [
              ...response1.data.itens,
              ...blindadoItems,
            ];

            setOffers(combinedOffers);
            setTotalAmount(
              response1.data.totalAmount + response2.data.totalAmount
            );
          })
          .finally(() => {
            setIsLoadingOffers(false);
          });
      } else {
        requestOffers(null)
          .then(handleResponse)
          .finally(() => {
            setIsLoadingOffers(false);
          });
      }
    } else if (modelCode !== 0 && color === '') {
      setIsLoadingOffers(true);
      const segmentIdNumber = Number(segmentId);
    
      const requestOffers = (salesChannel:any) =>
        services.postOffers({
          modelCode: [modelCode],
          brandCode: [],
          colors: [],
          minMonthlyInstallment: 0,
          maxMonthlyInstallment: 0,
          page: true,
          quantityPerPage: 10,
          currentPage: page,
          segmentId: segmentIdNumber,
          salesChannel: salesChannel,
        });
    
      const handleResponse = (response:any) => {
        setOffers(response.data.itens);
        setTotalAmount(response.data.totalAmount);
      };
    
      if (segmentIdNumber === 9) {
        Promise.all([
          requestOffers('S&D'),
          requestOffers('S&D - Blindado'),
        ])
          .then(([response1, response2]) => {
            const blindadoItems = response2.data.itens.map((item: any) => ({
              ...item,
              isBlindado: true,
            }));

            const combinedOffers:any = [
              ...response1.data.itens,
              ...blindadoItems,
            ];

            setOffers(combinedOffers);
            setTotalAmount(
              response1.data.totalAmount + response2.data.totalAmount
            );
          })
          .finally(() => {
            setIsLoadingOffers(false);
          });
      } else {
        requestOffers(null)
          .then(handleResponse)
          .finally(() => {
            setIsLoadingOffers(false);
          });
      }
    } else if (modelCode === 0 && color !== '') {
      setIsLoadingOffers(true);
      const segmentIdNumber = Number(segmentId);
    
      const requestOffers = (salesChannel:any) =>
        services.postOffers({
          modelCode: [],
          brandCode: [],
          colors: [color],
          minMonthlyInstallment: 0,
          maxMonthlyInstallment: 0,
          page: true,
          quantityPerPage: 10,
          currentPage: page,
          segmentId: segmentIdNumber,
          salesChannel: salesChannel,
        });
    
      const handleResponse = (response:any) => {
        setOffers(response.data.itens);
        setTotalAmount(response.data.totalAmount);
      };
    
      if (segmentIdNumber === 9) {
        Promise.all([
          requestOffers('S&D'),
          requestOffers('S&D - Blindado'),
        ])
          .then(([response1, response2]) => {
            const blindadoItems = response2.data.itens.map((item: any) => ({
              ...item,
              isBlindado: true,
            }));

            const combinedOffers:any = [
              ...response1.data.itens,
              ...blindadoItems,
            ];

            setOffers(combinedOffers);
            setTotalAmount(
              response1.data.totalAmount + response2.data.totalAmount
            );
          })
          .finally(() => {
            setIsLoadingOffers(false);
          });
      } else {
        requestOffers(null)
          .then(handleResponse)
          .finally(() => {
            setIsLoadingOffers(false);
          });
      }
    } else {
      setIsLoadingOffers(true);
      const segmentIdNumber = Number(segmentId);
    
      const requestOffers = (salesChannel:any) =>
        services.postOffers({
          modelCode: [],
          brandCode: [],
          colors: [],
          minMonthlyInstallment: 0,
          maxMonthlyInstallment: 0,
          page: true,
          quantityPerPage: 10,
          currentPage: page,
          segmentId: segmentIdNumber,
          salesChannel: salesChannel,
        });
    
      const handleResponse = (response:any) => {
        const { itens } = response.data;
    
        if (itens.length) {
          setOffers(itens);
          setTotalAmount(response.data.totalAmount);
        }
      };
    
      if (segmentIdNumber === 9) {
        Promise.all([
          requestOffers('S&D'),
          requestOffers('S&D - Blindado'),
        ])
          .then(([response1, response2]) => {
            const blindadoItems = response2.data.itens.map((item: any) => ({
              ...item,
              isBlindado: true,
            }));

            const combinedOffers:any = [
              ...response1.data.itens,
              ...blindadoItems,
            ];

            setOffers(combinedOffers);
            setTotalAmount(
              response1.data.totalAmount + response2.data.totalAmount
            );
          })
          .finally(() => {
            setIsLoadingOffers(false);
          });
      } else {
        requestOffers(null)
          .then(handleResponse)
          .finally(() => {
            setIsLoadingOffers(false);
          });
      }
    }
    

    // api.get(`/filters/${Number(segmentId)}`).then((response) => {
    //   setFilterModelOption(response.data.filters)
    // })
  }, [filter, page])


  // const handleChangeInvestment = async (e: any) => {
  //   const minValue = Math.min(...e) * (maxValueOffer / 100)
  //   const maxValue = Math.max(...e) * (maxValueOffer / 100)

  //   services
  //     .postOffers({
  //       modelCode: [],
  //       brandCode: [],
  //       colors: [],
  //       minMonthlyInstallment: minValue,
  //       maxMonthlyInstallment: maxValue,
  //       page: true,
  //       quantityPerPage: 10,
  //       currentPage: page,
  //       segmentId: Number(segmentId)
  //     })
  //     .then((response) => {
  //       setOffers(response.data.itens)
  //       setFilterModelOption(response.data.itens)
  //     })
  // }

  // const onChangeFilterModel = useCallback(
  //   (e: any) => {
  //     const filterModelCode: any = []

  //     const newArrayModelCode = e.map((item: any) => {
  //       return filterModelOption.filter((value: any) => {
  //         return value.model === item
  //       })
  //     })

  //     newArrayModelCode.map((item: any) => {
  //       item.map((subItem: any) => {
  //         filterModelCode.push(subItem.modelCode)
  //       })
  //     })

  //     services
  //       .postOffers({
  //         modelCode: [...filterModelCode],
  //         brandCode: [],
  //         colors: [],
  //         minMonthlyInstallment: 0,
  //         maxMonthlyInstallment: 0,
  //         page: true,
  //         quantityPerPage: 10,
  //         currentPage: page,
  //         segmentId: Number(segmentId)
  //       })
  //       .then((response) => {
  //         setOffers(response.data.itens)
  //       })
  //   },
  //   [page, filterModelOption]
  // )

  // const formatterValueRange = (value: any) => {
  //   return formatValue((value * maxValueOffer) / 100)
  // }

  const handleAddProducts = (product: any) => {
    const newProduct =[ product[product.length - 1]]
    setProducts([...products, ...newProduct])
  }

  const handleAddProductsStorage = () => {
    localStorage.setItem('@FleetSolutions:cart', JSON.stringify(products))
    history.push('/checkout')
  }

  const handlePagination = (value: any) => {
    setPage(value)
  }

  return (
    <Container>
      <Header />

      <div className="header-filter">
        {/* <div className="wrapper-filter">
          <img
            onClick={() => setOpenDrawer(!openDrawer)}
            src={filterIcon}
            alt="Filter"
          />
          <p>Filtrar pesquisa</p>
        </div> */}
        <div className="wrapper-title">
          <h1>
            {type === 'pj'
              ? 'Selecione veículos para empresas'
              : 'Alugue aqui seu novo veículo'}
          </h1>
        </div>
      </div>

      <Content>
        {/* <div>
          <Drawer
            style={{ height: 400 }}
            className="drawer"
            open={openDrawer}
            variant="persistent"
            anchor="left"
          >
            <Filter>
              <div className="info-value">
                <WrapperRange>
                  <div className="value-investiment">
                    <span>Filtro por valor</span>
                  </div>
                  <Slider
                    range
                    tipFormatter={formatterValueRange}
                    marks={marks}
                    defaultValue={[0, 100]}
                    min={0}
                    max={100}
                    onAfterChange={handleChangeInvestment}
                  />
                </WrapperRange>
              </div>

              <div className="model">
                <p>
                  <img width="35" height="28" src={carModelIcon} alt="Car" />
                  <span>Modelo</span>
                </p>
                <Checkbox.Group
                  options={filterModelOption.map((item: any) => item.model)}
                  onChange={onChangeFilterModel}
                />
              </div>
            </Filter>
          </Drawer>
        </div> */}

        <WrapperCards>
          {isLoadingOffers ? (
            <Loading text={'Carregando ofertas'} />
          ) : (
            <>
              <Cards>
                {offers.length ? (
                  offers.map((item: any) => {
                    const itemColor = item.productWithColor.filter(
                      (item: any) => item.isAvailability === true
                    )
                    return (
                      <CardOffer
                        handleAddProducts={handleAddProducts}
                        product={item}
                        type={type}
                        segmentId={segmentId}
                        brandCode={item.brandCode}
                        key={item.productId}
                        isColorOption={true}
                        modelCode={item.modelCode}
                        isAvailable={itemColor.length > 0}
                        productWithColor={item.productWithColor}
                        model={item.model}
                        monthlyInstallment={item.monthlyInstallment}
                      />
                    )
                  })
                ) : (
                  <p>Não há ofertas</p>
                )}
              </Cards>

              <Pagination
                onChange={handlePagination}
                defaultCurrent={1}
                current={page}
                total={totalAmount}
              />
            </>
          )}
        </WrapperCards>
      </Content>
      {quantityTotal > 0 && (
        <Cart>
          <span>Selecionados ({quantityTotal} veículos)</span>
          <Button onClick={handleAddProductsStorage}>CONTINUAR</Button>
        </Cart>
      )}
      <Footer />
    </Container>
  )
}

export default Offers
